import React, { useEffect, useMemo, useState } from 'react';
import { SELECTORS } from '@oneaudi/stck-store';
import Tile from './components/Tile';
import Tiles from './components/Tiles';
import { TileContext, TileContextState } from './hooks/tileHook';
import { Divider } from '@audi/audi-ui-react-v2';
import ResultsBar from './components/ResultsBar';
import {
  getScsUrlPartsFromConfiguration,
  useServicesContext,
  useSvd,
  VehicleBasic,
} from '@oneaudi/vtp-shared';
import ZeroResultsPage from './components/ZeroResultsPage';
import { CombinedConfig } from './FeatureHubAppDefinition';
import { AudiStockcarsStoreServiceV1 } from '@oneaudi/stockcars-store-service';
import { useSelector } from 'react-redux';

interface Props {}

const Favorites: React.FC<Props> = () => {
  const { featureAppConfig, getConfiguration, getEnvironmentConfig } = useServicesContext();
  const configuration = featureAppConfig as CombinedConfig;
  const newSearchUrl =
    configuration?.urls?.newSearchUrl !== undefined ? configuration?.urls?.newSearchUrl : '';
  const storeService = useServicesContext().getAdditionalService(
    'audi-stockcars-store-service'
  ) as AudiStockcarsStoreServiceV1;

  const [favoriteVehicles, setFavoriteVehicles] = useState<VehicleBasic[]>([]);
  const { svd } = useSvd();
  const scsUrlParts = getScsUrlPartsFromConfiguration(
    undefined,
    getConfiguration()!,
    getEnvironmentConfig()!
  );
  const favoriteVehicleIDs: string[] = useSelector((state) =>
    SELECTORS.VEHICLES.getFavoriteVehicleIdsState(state)
  );
  const vehicleQueryParamValues = favoriteVehicleIDs.map((id: string) => `vehicle.${id}`);

  useEffect(() => {
    if (!svd || !favoriteVehicleIDs) return;
    const getSortParam = () => {
      const storedSortValue = SELECTORS.SORTING.getSortingStateRaw(storeService.store.state);
      if (storedSortValue?.results) {
        return storedSortValue.results;
      }
      return configuration.sortParams.options?.length
        ? configuration.sortParams.options[0]
        : 'relevance';
    };

    const buildUrl = () => {
      const sortParam = encodeURIComponent(getSortParam());
      const filterParam = encodeURIComponent(vehicleQueryParamValues.join(','));
      const versionPath = scsUrlParts.versionsMapping.filter || scsUrlParts.defaultVersion;
      return `${scsUrlParts.scsBaseUrl}${versionPath}/search/filter/${scsUrlParts.marketPath}?svd=${svd}&size=${favoriteVehicleIDs.length}&sort=${sortParam}&filter=${filterParam}`;
    };

    const fetchData = async () => {
      const url = buildUrl();
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error fetching favorite vehicles');
        }
        const data = await response.json();
        setFavoriteVehicles(data.vehicleBasic as VehicleBasic[]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [svd, favoriteVehicleIDs]);

  const tileContextValue: TileContextState = useMemo(() => {
    return {
      viewType: 'list',
      setViewType: () => {},
      appContext: 'favorites',
      contentConfiguration: configuration,
    };
  }, [configuration]);

  return (
    <TileContext.Provider value={tileContextValue}>
      <ResultsBar />
      <Divider spaceStackStart="l" />
      {favoriteVehicles.length === 0 ? (
        <ZeroResultsPage context="favorites" newSearchUrl={newSearchUrl} />
      ) : (
        <>
          <Tiles>
            {favoriteVehicles.map((favoriteVehicle) => (
              <Tile
                key={favoriteVehicle.id}
                vehicle={favoriteVehicle}
                data-testid={`favorite_${favoriteVehicle.id}`}
              />
            ))}
          </Tiles>
        </>
      )}
    </TileContext.Provider>
  );
};

export default Favorites;
