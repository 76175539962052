import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
export const useFilterPresetUrl = () => {
    const [filters, presets] = useSelector((state) => {
        const activeFilters = [...SELECTORS.FILTERS.getPersistedFiltersMaps(state).values()]
            .map((filterDTO) => filterDTO.toString())
            .join(',');
        const activePresets = [...SELECTORS.FILTERS.getPersistedPresetsMap(state).values()]
            .map((filterDTO) => filterDTO.toString())
            .join(',');
        return [activeFilters, activePresets];
    });
    const filterString = `filter=${filters}`;
    const presetsString = `preset=${presets}`;
    const combinedString = `#${filters ? filterString : ''}${filters && presets ? '&' : ''}${presets ? presetsString : ''}`;
    return combinedString;
};
