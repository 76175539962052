import { gql } from '@oneaudi/onegraph-client';

export const SIM_CARS = gql`
  fragment SimCars on StockCar {
    id
    commissionNumber
    code {
      country
      language
    }
    dealer {
      id
      country
      hid
      name
      zip
      city
      street
      region
      phone
      fax
      email
      website
      imprint
      position {
        latitude
        longitude
      }
    }
    colorInfo {
      interiorColor {
        colorInfo {
          code
          text
        }
        baseColorInfo {
          code
          text
        }
        label
      }
      exteriorColor {
        label
        colorInfo {
          code
          text
        }
        baseColorInfo {
          code
          text
        }
      }
      topColor {
        label
      }
    }
    carline {
      id
      name
    }
    techDataGroups {
      techDataList {
        label
        text
        id
      }
      label
      id
    }
    model {
      id {
        year
        code
        version
        extensions
      }
      name
      salesModelyear
    }
    carPrices {
      type
      label
      price {
        formattedValue
        valueAsText
        value
        currencyDetails {
          code
          symbol
        }
      }
    }
    descriptionByDealer
    doors
    driveText
    emissionDecalCode
    gearText
    engineInfo {
      consumption {
        label
        engineTypes {
          id
          label
          wltp {
            types {
              id
              label
              values {
                id
                label
                valueText
                unitText
                highlighting
              }
            }
            label
            link {
              label
              url
            }
          }
          nedc {
            label
            link {
              label
              url
            }
            types {
              id
              label
              values {
                id
                label
                valueText
                unitText
                highlighting
              }
            }
          }
        }
      }
      emission {
        label
        engineTypes {
          id
          label
          wltp {
            label
            link {
              label
              url
            }
            types {
              id
              label
              values {
                id
                label
                unitText
                valueText
                highlighting
              }
            }
          }
          nedc {
            label
            link {
              label
              url
            }
            types {
              id
              label
              values {
                id
                label
                valueText
                unitText
                highlighting
              }
            }
          }
        }
      }
      fuel {
        code
        text
      }
      engineDisplacement {
        unitText
        value {
          number
          formatted
        }
      }
      maxPowerKW {
        unitText
        value {
          number
          formatted
        }
      }
      maxPowerPS {
        unitText
        value {
          number
          formatted
        }
      }
      range {
        label
        engineTypes {
          id
          label
          wltp {
            label
            link {
              label
              url
            }
            types {
              id
              label
              values {
                id
                label
                valueText
                unitText
                highlighting
              }
            }
          }
          nedc {
            label
            link {
              label
              url
            }
            types {
              id
              label
              values {
                id
                label
                unitText
                valueText
                highlighting
              }
            }
          }
        }
      }
    }
    envkv {
      ... on StockCarEnvkv2024 {
        labelUrlPDF
        labelUrlSVG
        legalCartypeCode
        consumptionTax {
          text
          unitText
        }
        creationDateText
        co2CostStartYearText
        co2CostEndYearText
        fuelTariffReferenceYearText
        defaultMileage {
          text
          unitText
        }
        driveText
        co2Class {
          combinedClassText
          emptyBatteryClassText
        }
        co2Costs {
          medium {
            tariff {
              text
              unitText
            }
            costs {
              text
              unitText
            }
          }
          low {
            tariff {
              unitText
              text
            }
            costs {
              text
              unitText
            }
          }
          high {
            costs {
              text
              unitText
            }
            tariff {
              text
              unitText
            }
          }
        }
        energyCosts {
          text
          unitText
        }
        primaryFuel {
          id
          consumptionUnitText
          tariffText
          tariffUnitText
          typeText
          unitText
        }
        secondaryFuel {
          id
          consumptionUnitText
          tariffText
          tariffUnitText
          typeText
          unitText
        }
      }
    }
    paddingTypeText
    titleText
    subtitleText
    salesInfo {
      isBuyOnline
      saleOrderTypeText
      orderStatusText
      physicalOrderStatus
      availableFromDateInfo {
        value
        type
      }
      offerDateInfo {
        value
        type
      }
    }
    preUse {
      code
      text
    }
    preowners
    mileage {
      unitText
      value {
        number
        formatted
      }
    }
    generalInspectionDateInfo {
      value
      type
    }
    initialRegistrationDateInfo {
      value
      type
    }
    weblink
    images {
      id {
        group
        image
      }
      url
      type
      mimeType
      width
      height
    }
    qualityLabel {
      name
      label
      imageURL
      legalEntity
    }
    warranties {
      sortOrder
      name
      label
      dealerCondition
      dateStart
      dateEnd
      documentURL
      durationValue
      durationUnitCode
      durationUnitText
      distanceValue
      distanceUnitCode
      distanceUnitText
      legalEntity
    }
    vatReclaimable
    colorInfo {
      interiorColor {
        colorInfo {
          code
          text
        }
        baseColorInfo {
          code
          text
        }
        label
      }
      exteriorColor {
        colorInfo {
          code
          text
        }
        label
      }
      topColor {
        colorInfo {
          code
          text
        }
        label
      }
    }
    cartypeText
    manufacturerSpecificItems {
      ... on StockCarManufacturerAudi {
        cdbItems {
          id
          value
          textInfos {
            id
            value
          }
        }
        cdbCategories {
          id
          label
          categories {
            id
            label
          }
        }
      }
      ... on StockCarManufacturer3rdParty {
        name
      }
    }
  }
`;
export const similarCarsQuery = gql`
  ${SIM_CARS}
  query similarCarsByStockCar(
    $stockCarIdentifier: StockCarIdentifierInput!
    $similarCars: SimilarCarsInput
  ) {
    stockCarExtended(stockCarIdentifier: $stockCarIdentifier) {
      similarCars {
        newCars(input: $similarCars) {
          cars {
            score
            stockCar {
              ...SimCars
            }
          }
        }
        usedCars(input: $similarCars) {
          cars {
            stockCar {
              ...SimCars
            }
            score
          }
        }
      }
    }
  }
`;

export const singleStockCarQuery = gql`
  ${SIM_CARS}
  query getSingleStockCar($stockCarIdentifier: StockCarIdentifierInput!) {
    stockCar(stockCarIdentifier: $stockCarIdentifier) {
      ...SimCars
    }
  }
`;
