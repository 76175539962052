import { TestCycleTypesFromConfig, } from '@oneaudi/vtp-configuration-service';
import { useServicesContext } from '../context';
const getVehicleIdsFromUrl = (url = location.href) => {
    const regex = /(sc_detail.*?\.)(([^.\s])+)/gi;
    let matchesArr;
    const vehicleIds = [];
    while ((matchesArr = regex.exec(url))) {
        vehicleIds.push(matchesArr[2]);
    }
    return vehicleIds;
};
export const getVehicleIdFromUrlParam = (url) => {
    let vehicleId = '';
    if (new RegExp('^(https?:\\/\\/)').test(url)) {
        const { searchParams } = new URL(url);
        vehicleId = searchParams.get('vehicleid') || searchParams.get('vehicleId');
    }
    if (url.includes('?')) {
        const [, paramString] = url.split('?');
        const searchParams = new URLSearchParams(paramString);
        vehicleId = searchParams.get('vehicleid') || searchParams.get('vehicleId');
    }
    return vehicleId || '';
};
const getVehicleIdFromUrl = (url = location.href) => {
    const vehicleIdParam = getVehicleIdFromUrlParam(url);
    const vehicleIds = getVehicleIdsFromUrl(url);
    const seoVehicleId = getSEOUrlId(url);
    return vehicleIdParam || vehicleIds.pop() || seoVehicleId || '';
};
const getSEOUrlId = (url) => {
    let seoUrlIds = url.match(/\/id.*\//);
    if (!(seoUrlIds === null || seoUrlIds === void 0 ? void 0 : seoUrlIds.length)) {
        seoUrlIds = url.match(/\/id.*$/);
        if (!(seoUrlIds === null || seoUrlIds === void 0 ? void 0 : seoUrlIds.length)) {
            return '';
        }
    }
    const seoUrlId = seoUrlIds.pop().replace(/^\/id/, '').replace(/\/$/, '');
    return seoUrlId;
};
const getVehicleIdByUrlAndContext = (url = location.href, element) => {
    let vehicleId;
    const vehicleIds = getVehicleIdsFromUrl(url);
    const layer = document.querySelector('.nm-layer-opened .nm-layer');
    if (vehicleIds.length) {
        vehicleId = layer.contains(element) ? vehicleIds[vehicleIds.length - 1] : vehicleIds[0];
    }
    vehicleId = getVehicleIdFromUrlParam(url);
    return vehicleId;
};
const isPHEV = (vehicle) => {
    var _a, _b;
    if ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _a === void 0 ? void 0 : _a.engineType) {
        return vehicle.vlsEnergyProvision.engineType === 'OVC_HEV';
    }
    return ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.io) === null || _b === void 0 ? void 0 : _b.engineType) === 'OVC_HEV';
};
const isPrimaryElectrical = (vehicle) => { var _a; return ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.io) === null || _a === void 0 ? void 0 : _a.fuels[0].fuel) === 'ELECTRICAL'; };
const hasElectricalEngine = (vehicle) => {
    var _a, _b, _c, _d, _e;
    if ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _a === void 0 ? void 0 : _a.fuels) {
        return (((_c = (_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _b === void 0 ? void 0 : _b.fuels) === null || _c === void 0 ? void 0 : _c.find((fuelType) => fuelType.fuel === 'ELECTRICAL')) !== undefined);
    }
    return ((_e = (_d = vehicle === null || vehicle === void 0 ? void 0 : vehicle.io) === null || _d === void 0 ? void 0 : _d.fuels) === null || _e === void 0 ? void 0 : _e.find((fuelType) => fuelType.fuel === 'ELECTRICAL')) !== undefined;
};
const getElectricalData = (vehicle) => {
    var _a, _b, _c, _d;
    if ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _a === void 0 ? void 0 : _a.fuels) {
        return (_b = vehicle.vlsEnergyProvision.fuels) === null || _b === void 0 ? void 0 : _b.find((typedFuel) => {
            return typedFuel.fuel === 'ELECTRICAL';
        });
    }
    return (_d = (_c = vehicle === null || vehicle === void 0 ? void 0 : vehicle.io) === null || _c === void 0 ? void 0 : _c.fuels) === null || _d === void 0 ? void 0 : _d.find((typedFuel) => {
        return typedFuel.fuel === 'ELECTRICAL';
    });
};
const getConsumptionValueUnitTuple = (fuel = {}, key = TestCycleTypesFromConfig.WLTP) => {
    var _a;
    const { consolidated: value = '', unit = '' } = ((_a = fuel === null || fuel === void 0 ? void 0 : fuel.consumption) === null || _a === void 0 ? void 0 : _a[key]) || {
        consolidated: '',
        unit: '',
    };
    return [value, unit];
};
const getEmissionValueUnitTuple = (fuel = {}, key = TestCycleTypesFromConfig.WLTP) => {
    var _a, _b;
    const { consolidated: value = '', unit = '' } = ((_b = (_a = fuel === null || fuel === void 0 ? void 0 : fuel.emission) === null || _a === void 0 ? void 0 : _a.co2) === null || _b === void 0 ? void 0 : _b[key]) || {
        consolidated: '',
        unit: '',
    };
    return [value, unit];
};
const getRangeValueUnitTuple = (fuel = {}, key = TestCycleTypesFromConfig.WLTP) => {
    var _a;
    const { consolidated: value = '', unit = '' } = ((_a = fuel === null || fuel === void 0 ? void 0 : fuel.range) === null || _a === void 0 ? void 0 : _a[key]) || {
        consolidated: '',
        unit: '',
    };
    return [value, unit];
};
const getTilesEmissionRepresentation = () => {
    var _a, _b;
    const { getConfiguration } = useServicesContext();
    const emissionRepresentationFromConfig = (_b = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.consumptionEmission) === null || _b === void 0 ? void 0 : _b.tilesEmissionRepresentation;
    return emissionRepresentationFromConfig || [TestCycleTypesFromConfig.WLTP];
};
const getConsumptionEmissionRepresentation = () => {
    var _a, _b;
    const { getConfiguration } = useServicesContext();
    const emissionRepresentationFromConfig = (_b = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.consumptionEmission) === null || _b === void 0 ? void 0 : _b.consumptionEmissionRepresentation;
    return emissionRepresentationFromConfig || [TestCycleTypesFromConfig.WLTP];
};
const getActualTestCycles = (vehicle, emissionRepresentation) => {
    var _a, _b;
    const efficiencyNode = ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _a === void 0 ? void 0 : _a.hasWltp) || ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _b === void 0 ? void 0 : _b.hasNedc)
        ? vehicle.vlsEnergyProvision
        : vehicle.io;
    const actualTestCyles = emissionRepresentation
        .map((testCycle) => {
        if (efficiencyNode.hasNedc && testCycle === TestCycleTypesFromConfig.NEDC) {
            return TestCycleTypesFromConfig.NEDC;
        }
        if (efficiencyNode.hasWltp && testCycle === TestCycleTypesFromConfig.WLTP) {
            return TestCycleTypesFromConfig.WLTP;
        }
        return null;
    })
        .filter((entry) => entry !== null);
    if (!actualTestCyles.length && emissionRepresentation.length === 1) {
        if (emissionRepresentation[0] === TestCycleTypesFromConfig.NEDC && efficiencyNode.hasWltp) {
            return [TestCycleTypesFromConfig.WLTP];
        }
        if (emissionRepresentation[0] === TestCycleTypesFromConfig.WLTP && efficiencyNode.hasNedc) {
            return [TestCycleTypesFromConfig.NEDC];
        }
        return [];
    }
    return actualTestCyles;
};
const getEfficiencyClass = (vehicle) => {
    var _a, _b, _c, _d;
    if (!vehicle) {
        return '';
    }
    if (((_b = (_a = vehicle.vlsEnergyProvision) === null || _a === void 0 ? void 0 : _a.fuels) === null || _b === void 0 ? void 0 : _b.length) > 0 && vehicle.efficiencyClass) {
        return vehicle.efficiencyClass;
    }
    const vlsEfficiency = (_c = vehicle.vlsEfficiencyClass) === null || _c === void 0 ? void 0 : _c.code;
    if (vlsEfficiency) {
        return vlsEfficiency;
    }
    const envkvIODataEfficiency = (_d = vehicle.envkvIOData) === null || _d === void 0 ? void 0 : _d.efficiencyClass;
    if (envkvIODataEfficiency) {
        return envkvIODataEfficiency;
    }
    return '';
};
const isAvailableSoonVehicle = (vehicle, scopeShowAvailableSoon = false) => {
    var _a;
    const isAvailableFromCodeDate = (((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.dealer) === null || _a === void 0 ? void 0 : _a.id) === '03611' || scopeShowAvailableSoon) &&
        (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) &&
        (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'date';
    const isAvailableFromCodeSoon = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'soon';
    return isAvailableFromCodeDate || isAvailableFromCodeSoon;
};
const shouldUseTextkeyForAvailableNow = (vehicle, showAvailabilityNowConfigured) => (vehicle.availableFromCode === 'now' && showAvailabilityNowConfigured) || false;
const isBevAgencyVehicle = (vehicle) => {
    var _a;
    const BEV_AGENCY_CODES = ['agency_model', 'agency_model_dealer'];
    const businessModelCode = (_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.businessModel) === null || _a === void 0 ? void 0 : _a.code;
    if (!businessModelCode)
        return false;
    return BEV_AGENCY_CODES.includes(businessModelCode);
};
const hasBevAgencyDealerLabel = (vehicle) => {
    var _a;
    const BEV_AGENCY_CODES_WITH_DEALER = ['agency_model_dealer'];
    const businessModelCode = (_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.businessModel) === null || _a === void 0 ? void 0 : _a.code;
    if (!businessModelCode)
        return false;
    return BEV_AGENCY_CODES_WITH_DEALER.includes(businessModelCode);
};
const isNationWideSellingVehicle = (vehicle) => {
    var _a;
    if (!vehicle)
        return false;
    const NWS_CRITERIAS = ['nsc_stock'];
    const businessModelCode = (_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.businessModel) === null || _a === void 0 ? void 0 : _a.code;
    if (!businessModelCode)
        return vehicle === null || vehicle === void 0 ? void 0 : vehicle.nationWideSelling;
    return NWS_CRITERIAS.includes(businessModelCode);
};
export { getEfficiencyClass, getActualTestCycles, getRangeValueUnitTuple, getEmissionValueUnitTuple, getConsumptionValueUnitTuple, isPHEV, isPrimaryElectrical, getElectricalData, hasElectricalEngine, getVehicleIdFromUrl, isAvailableSoonVehicle, shouldUseTextkeyForAvailableNow, getVehicleIdByUrlAndContext, isBevAgencyVehicle, hasBevAgencyDealerLabel, isNationWideSellingVehicle, getTilesEmissionRepresentation, getConsumptionEmissionRepresentation, };
