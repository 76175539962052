import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import { Iframe } from './layer/Iframe';
export function usePostData(vehicle, dataProfile, vehicleRaw) {
    var _a;
    const [postData, setPostData] = useState('');
    const skuItems = (_a = vehicleRaw === null || vehicleRaw === void 0 ? void 0 : vehicleRaw.detail) === null || _a === void 0 ? void 0 : _a.skuItems;
    useSelector((state) => {
        const products = SELECTORS.AOZ.getAddedProductsByVehicleId(state, vehicle.id);
        const financing = dataProfile !== 'no-finance-data'
            ? SELECTORS.FINANCING.getSummaryOnePointFive(state, vehicle.id)
            : {};
        const filtersArray = [...SELECTORS.FILTERS.getSelectedFiltersMap(state).values()].map((filterItem) => filterItem.toString());
        const dealerFilters = filtersArray.filter((filter) => filter.startsWith('dealer'));
        const dealerIds = dealerFilters.map((dealerFilter) => dealerFilter.split('.')[1]);
        const postDataString = JSON.stringify([
            { aoz: products },
            { audicode: vehicle.audiCode },
            { financingData: financing },
            { vehicleData: vehicle },
            { dealerFilterIds: dealerIds },
            { skuItems },
        ]);
        if (postData !== postDataString) {
            setPostData(postDataString);
        }
    });
    return postData;
}
export const PostFormV2 = ({ link, postData, }) => {
    const formRef = useCallback((form) => {
        if (form) {
            form.submit();
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { action: link.url, id: "tile-post-form", target: "cta-iframe", method: "post", hidden: true, ref: formRef },
            React.createElement("input", { type: "hidden", name: "inquiry_json_data", value: postData })),
        React.createElement(Iframe, { src: "", originUrl: link.url })));
};
