import { formatUrl, isBevAgencyVehicle, isNationWideSellingVehicle } from '../../utils';
export const filterCTAButtons = (rawCTAButtons, vehicle, appContext, showAsText, isTilesButtonMode, LiteReservationButton) => {
    let buttons = filterforFA(rawCTAButtons, appContext);
    buttons = filterForDealer(buttons, vehicle);
    buttons = replaceliteReservationButton(LiteReservationButton, buttons);
    buttons = handlePhoneButton(buttons, vehicle);
    if (appContext === 'tiles') {
        buttons = filterForTiles(buttons, showAsText, isTilesButtonMode);
    }
    buttons = filterButtonsBasedOnReservationStatus(buttons, vehicle.reservation);
    buttons = filterButtonsBasedOnBuyableOnlineStatus(buttons, vehicle.buyableOnline);
    if (!vehicle.buyableOnline) {
        buttons = removeEcomButtons(buttons);
    }
    if (!vehicle.financing) {
        buttons = removeFinancingButtons(buttons);
    }
    buttons = buttons.map((button) => {
        if (button.url) {
            const formattedUrlButton = Object.assign(Object.assign({}, button), { url: formatUrl(button.url, vehicle) });
            return formattedUrlButton;
        }
        return button;
    });
    return handleExclusiveButtons(buttons, vehicle);
};
export const handleExclusiveButtons = (buttons, vehicle) => {
    if (vehicle.leasingCar) {
        return filterForLeasing(buttons);
    }
    if (isNationWideSellingVehicle(vehicle)) {
        return filterForNws(buttons);
    }
    if (isBevAgencyVehicle(vehicle)) {
        return filterForBev(buttons);
    }
    return buttons.filter((button) => button.type !== 'leasing' && button.type !== 'nws' && button.type !== 'bevAgency');
};
export const filterForTiles = (buttons, showAsText, isTilesButtonMode) => {
    let tilesFilteredButton = buttons;
    if (showAsText) {
        tilesFilteredButton = removeDetailsButtons(buttons);
    }
    const DetailsPageButtonIndex = tilesFilteredButton.findIndex((button) => button.type === 'details');
    if (DetailsPageButtonIndex !== -1) {
        if (!showAsText && !isTilesButtonMode) {
            return [tilesFilteredButton[DetailsPageButtonIndex]];
        }
    }
    else if (!showAsText && !isTilesButtonMode) {
        return [];
    }
    return tilesFilteredButton;
};
export const filterforFA = (buttons, appContext) => {
    return buttons.filter((button) => button.displayOption === appContext || button.displayOption === 'both');
};
export const filterForDealer = (buttons, vehicle) => {
    return buttons.filter((button) => {
        var _a, _b;
        if (button.options &&
            button.options.filterByDealerId &&
            button.options.filterByDealerId.filterList) {
            const dealerArray = (_b = (_a = button === null || button === void 0 ? void 0 : button.options) === null || _a === void 0 ? void 0 : _a.filterByDealerId) === null || _b === void 0 ? void 0 : _b.filterList.split(',').map((item) => item.trim());
            const dealerIdInArray = dealerArray.find((dealerId) => dealerId === vehicle.dealer.id);
            if (button.options.filterByDealerId.filterType === 'include') {
                return dealerIdInArray;
            }
            if (button.options.filterByDealerId.filterType === 'exclude') {
                return !dealerIdInArray;
            }
        }
        if (button.options &&
            button.options.filterByDealerId &&
            !button.options.filterByDealerId.filterList &&
            button.options.filterByDealerId.filterType === 'include') {
            return false;
        }
        return true;
    });
};
export const removeDetailsButtons = (buttons) => {
    return buttons.filter((button) => button.type !== 'details');
};
export const filterButtonsBasedOnReservationStatus = (buttons, reserved) => {
    if (reserved) {
        return buttons.filter((button) => button.reservation !== 'notReserved');
    }
    return buttons.filter((button) => button.reservation !== 'reserved');
};
export const filterButtonsBasedOnBuyableOnlineStatus = (buttons, buyableOnline) => {
    if (buyableOnline) {
        return buttons.filter((button) => button.buyableOnline !== 'not-buyableOnline');
    }
    return buttons.filter((button) => button.buyableOnline !== 'buyableOnline');
};
export const removeEcomButtons = (buttons) => {
    return buttons.filter((button) => button.type !== 'ecom');
};
export const removeFinancingButtons = (buttons) => {
    return buttons.filter((button) => button.type !== 'finance-checkout');
};
export const filterForLeasing = (buttons) => {
    return buttons.filter((button) => button.type !== 'nws' && button.type !== 'bevAgency' && button.type !== 'contact');
};
export const filterForNws = (buttons) => {
    return buttons.filter((button) => button.type !== 'leasing' && button.type !== 'bevAgency' && button.type !== 'contact');
};
export const filterForBev = (buttons) => {
    return buttons.filter((button) => button.type !== 'leasing' && button.type !== 'nws' && button.type !== 'contact');
};
export const searchForPhoneButton = (buttons, appContext) => {
    return buttons.find((button) => button.type === 'phone' &&
        (button.displayOption === appContext || button.displayOption === 'both'));
};
export const removePhoneButton = (buttons) => {
    return buttons.filter((button) => button.type !== 'phone');
};
export const replaceliteReservationButton = (LiteReservationButton, buttons) => {
    let newButtons = buttons;
    if (LiteReservationButton) {
        const indexToReplace = buttons.findIndex((button) => button.type === 'liteReservation');
        newButtons[indexToReplace] = LiteReservationButton;
    }
    else {
        newButtons = buttons.filter((button) => button.type !== 'liteReservation');
    }
    return newButtons;
};
export const parsePhoneNumber = (input) => {
    return input.replace('/', '');
};
export const getDealerPhone = (vehicle) => {
    if (vehicle.dealer.phoneNumbers) {
        const dealerPhoneObject = vehicle.dealer.phoneNumbers.find((phoneNumber) => phoneNumber.type === 'phone');
        if (dealerPhoneObject === null || dealerPhoneObject === void 0 ? void 0 : dealerPhoneObject.number) {
            return parsePhoneNumber(dealerPhoneObject.number);
        }
    }
    return undefined;
};
export const handlePhoneButton = (buttons, vehicle) => {
    const dealerPhoneNumber = getDealerPhone(vehicle);
    if (dealerPhoneNumber) {
        const newButtons = buttons;
        const phoneButtonIndex = newButtons.findIndex((button) => button.type === 'phone');
        if (phoneButtonIndex !== -1) {
            newButtons[phoneButtonIndex].url = dealerPhoneNumber;
        }
        return newButtons;
    }
    return removePhoneButton(buttons);
};
export const getFetchOptions = (environmentConfig, vehicle, button) => {
    const getPDFUrl = (audiCode) => {
        let url = '';
        if (audiCode && typeof window !== 'undefined' && environmentConfig.pdfBaseURL !== '')
            url = `${environmentConfig.pdfBaseURL}/${audiCode}`;
        url.replace('/stck/', '/audicode/ecom/');
        return url;
    };
    const fetchOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        cache: 'no-cache',
        body: JSON.stringify({
            pdfUrl: getPDFUrl(vehicle.id),
            vehicle: Object.assign(Object.assign({}, vehicle), { financing: button.dataProfile !== 'no-finance-data' ? vehicle.financing : {} }),
        }),
    };
    return fetchOptions;
};
export const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
};
