import { Accordion, AccordionSection } from '@audi/audi-ui-react-v2';
import React, { useEffect } from 'react';
import { registerSuggestedCarsImpressionTracking } from './tracking';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { useServicesContext } from '@oneaudi/vtp-shared';
import { CombinedConfig } from '../FeatureHubAppDefinition';
import { InitialTab } from '../../@types/editor';

type SuggestedMobileWrapperProps = React.PropsWithChildren & {
  isMobile: boolean;
  tabState: InitialTab;
};
const SuggestedMobileWrapper: React.FC<SuggestedMobileWrapperProps> = ({
  children,
  isMobile,
  tabState,
}) => {
  const configuration = useServicesContext().featureAppConfig as CombinedConfig;
  const ref = React.useRef(null);
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  useEffect(() => {
    registerSuggestedCarsImpressionTracking(
      trackingService,
      ref,
      configuration.appContext,
      tabState
    );
  }, []);
  return isMobile ? (
    <Accordion ref={ref}>
      <AccordionSection headingLevel="h2" id="section" label="Empfohlene Fahrzeuge">
        {children}
      </AccordionSection>
    </Accordion>
  ) : (
    <div ref={ref}>{children}</div>
  );
};
export default SuggestedMobileWrapper;
