import { useMemo } from 'react';
import { useServicesContext } from '../../context';
export const useScsUrlParts = () => {
    const { getConfigurationEntry, getConfiguration, getEnvironmentConfig } = useServicesContext();
    const { versionsMapping, marketPath, language, market, scsBaseUrl, defaultVersion, isUsedCarMkt, } = getScsUrlPartsFromConfiguration(getConfigurationEntry, getConfiguration(), getEnvironmentConfig());
    return useMemo(() => ({
        versionsMapping,
        marketPath,
        language,
        market,
        scsBaseUrl,
        defaultVersion,
        isUsedCarMkt,
    }), [defaultVersion, language, market, marketPath, scsBaseUrl, versionsMapping, isUsedCarMkt]);
};
export const getScsUrlPartsFromConfiguration = (getConfigurationEntry, configuration, envConfig) => {
    var _a;
    if (!getConfigurationEntry) {
        getConfigurationEntry = () => null;
    }
    let versionsMapping = null;
    if (envConfig) {
        versionsMapping = envConfig.scs.apiVersionMapping;
    }
    else {
        const rawVersionMapping = getConfigurationEntry('ScsApiVersionMapping');
        try {
            versionsMapping = JSON.parse(rawVersionMapping);
        }
        catch (err) {
            versionsMapping = null;
        }
    }
    const defaultVersion = (envConfig === null || envConfig === void 0 ? void 0 : envConfig.scs.defaultApiVersion) || getConfigurationEntry('ScsDefaultApiVersion');
    const marketPath = ((_a = configuration === null || configuration === void 0 ? void 0 : configuration.scs) === null || _a === void 0 ? void 0 : _a.scsMarketPath) || getConfigurationEntry('ScsMarketPath');
    const [language, market] = marketPath ? marketPath.split('/') : [];
    const scsBaseUrl = (envConfig === null || envConfig === void 0 ? void 0 : envConfig.scs.baseUrl) || getConfigurationEntry('ScsBaseUrl');
    const isUsedCarMkt = marketPath === null || marketPath === void 0 ? void 0 : marketPath.includes('uc');
    return {
        versionsMapping,
        marketPath,
        language,
        market,
        scsBaseUrl,
        defaultVersion,
        isUsedCarMkt,
    };
};
