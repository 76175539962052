import * as React from 'react';
import styled from 'styled-components';
// @ts-ignore
import warrantyPlus from './assets/GW-plus.svg';
// @ts-ignore
import warranty5Years from './assets/GW-plus_Garantie.svg';
import {
  Icon,
  iconSystemInfoSmall,
  resolvePathFromContentReference,
  useServicesContext,
} from '@oneaudi/vtp-shared';
import { CombinedConfig } from '../../../FeatureHubAppDefinition';
import { useI18n } from '@oneaudi/i18n-context';
import { Button, ButtonProps } from '@audi/audi-ui-react-v2';
import { FocusLayerSizeV2, LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@oneaudi/fa-one-layer';

interface WarrantyIconProps {
  type: string;
  isSeal: boolean;
}
const WarrantyPlus = styled.div`
  margin-left: var(${(props): string => props.theme.responsive.spacing.s});
`;
const Warranty5Years = styled.div`
  display: inline-block;
  height: 90px;
`;
const WarrantyPlusExternal = styled.div`
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 100px;
  margin-left: var(${(props): string => props.theme.responsive.spacing.s});
`;
const WarrantyExternalImage = styled.img`
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: var(${(props): string => props.theme.responsive.spacing.s});
`;
const WarrantyImage = styled.img`
  width: 60px;
  height: 20px;
  display: inline-block;
`;
const WarrantyInfoIconBtn = styled((props) => <Button {...props} />)<ButtonProps>`
  margin-left: var(${(props): string => props.theme.responsive.spacing.s});
  margin-bottom: var(${(props): string => props.theme.responsive.spacing.s});
`;
export const WarrantyIcon: React.FC<WarrantyIconProps> = ({ type, isSeal }) => {
  const { featureAppConfig, getConfiguration } = useServicesContext();
  const vtpConfiguration = getConfiguration();
  const externalWarrantyLogoUrl = vtpConfiguration?.assets?.warrantyPlusLogo
    ? resolvePathFromContentReference(vtpConfiguration?.assets?.warrantyPlusLogo)
    : vtpConfiguration?.urls?.warrantyPlusLogoURL ||
      (featureAppConfig as CombinedConfig).warrantyPlusLogoUrl;
  const hasExternalLogo =
    externalWarrantyLogoUrl &&
    externalWarrantyLogoUrl !== '' &&
    externalWarrantyLogoUrl !== undefined;
  const altTextForWarrantyExternalLogo = useI18n({
    id: 'nemo.ui.sc.warranty.external.img.alt',
    defaultMessage: '',
  });
  const altTextForWarrantyPlusLogo = useI18n({
    id: 'nemo.ui.sc.warrantyPlus.internal.img.alt',
    defaultMessage: '',
  });
  const altTextForWarrantyLogo = useI18n({
    id: 'nemo.ui.sc.warranty.internal.img.alt',
    defaultMessage: '',
  });
  const warrantyInfoUrl = (featureAppConfig as CombinedConfig)?.tile.warrantyInfoUrl;
  const layerManager = useServicesContext().getAdditionalService(
    'gfa:layer-manager'
  ) as LayerManagerV27;

  const openLayer = () => {
    layerManager?.openFocusLayer(
      () => <LayerContentHTML url={warrantyInfoUrl!.replace(/\.html$/, '.headless.html')} />,
      {},
      {
        size: FocusLayerSizeV2.A,
        userCloseable: true,
      }
    );
  };
  return (
    <>
      {(type === 'WarrantyTypePlus' || type === 'gwplus' || isSeal) && hasExternalLogo && (
        <WarrantyPlusExternal data-testid="warranty-plus-logo-external">
          <WarrantyExternalImage
            src={externalWarrantyLogoUrl}
            alt={altTextForWarrantyExternalLogo}
          />
          {warrantyInfoUrl && (
            <WarrantyInfoIconBtn
              variant="text"
              data-testid="warrantyInfoIcon_ext"
              onClick={openLayer}
              icon={<Icon icon={iconSystemInfoSmall} />}
            />
          )}
        </WarrantyPlusExternal>
      )}
      {(type === 'WarrantyTypePlus' || type === 'gwplus' || isSeal) && !hasExternalLogo && (
        <WarrantyPlus data-testid="warranty-plus-logo">
          <WarrantyImage src={warrantyPlus} alt={altTextForWarrantyPlusLogo} />
          {warrantyInfoUrl && (
            <WarrantyInfoIconBtn
              variant="text"
              data-testid="warrantyInfoIcon"
              onClick={openLayer}
              icon={<Icon icon={iconSystemInfoSmall} />}
            />
          )}
        </WarrantyPlus>
      )}
      {(type === 'WarrantyType5Years' ||
        type === 'gwplus5j' ||
        type === 'WarrantyTypeAsgExtended' ||
        type === 'asg_extended') && (
        <Warranty5Years>
          <img src={warranty5Years} width="68" height="90" alt={altTextForWarrantyLogo} />
        </Warranty5Years>
      )}
    </>
  );
};
export default WarrantyIcon;
