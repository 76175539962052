var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ACTIONS, SELECTORS } from '@oneaudi/stck-store';
import { useI18n } from '@oneaudi/i18n-context';
import { Button, RadioButton, Text, Divider } from '@audi/audi-ui-react-v2';
import { prepareDefaultDataForProduct, generateFormData, escapeRegExp } from './form-helper';
import { getFormValues, RadioWrapper, renderHiddenField, renderRadioField as renderRadioFields, renderRateField, renderReadonlyField, renderSelectField, renderTextField, } from './form-fields';
import { InfoIcon } from './InfoIcon';
import FinanceModalCTA from './FinanceModalCTA';
import FinanceCTAs from './FinanceCTAs';
import { useSvd } from '../../../../hooks';
import { useServicesContext } from '../../../../context';
import { getFSAGDefaultProduct, patchFormParameter, patchProductData, } from '../crs-helper';
import { getActiveCampaigns, resetFinancingCampaign } from '../campaign-helper';
import { getDefaultProduct, getProducts } from '../../services/crs/api';
import { DangerousElement } from '../../../DangerousElement';
import { deepCopy } from '../../../../utils/object';
import { trackUpdateClick, trackClick } from '../../../ctaButtons/tracking';
import { Icon, iconCautionSmall } from '../../../icon';
import { FormWrapper, RadioWrapperToOverwriteLegacyCSS, StyledButtonGroup, FormElementWrapper, ReadOnlyFormElementWrapper, ReadOnlyGroup, RateWrapper, WebCalcErrWrapper, WebCalcErrContainer, FormColumnWrapper, ReadOnlyColumn, ProductWrapper, popupContentStyles, HeadingWrapper, } from './financeModal-styles';
export const FinanceModal = ({ fullVehicle, configureFinanceComponents, onClose, onError, setCalculationError, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { audiMarketContextService, audiFetchConfigService, appContextId, getConfiguration, getEnvironmentConfig, } = useServicesContext();
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const shiftCurrencySymbolLeftToRight = configureFinanceComponents === null || configureFinanceComponents === void 0 ? void 0 : configureFinanceComponents.shiftCurrencySymbolLeftToRight;
    const includeRateInSummaryBreakdown = configureFinanceComponents === null || configureFinanceComponents === void 0 ? void 0 : configureFinanceComponents.includeRateInSummaryBreakdown;
    const showSelectedProductOptionsInBreakdown = configureFinanceComponents === null || configureFinanceComponents === void 0 ? void 0 : configureFinanceComponents.showSelectedProductOptionsInBreakdown;
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const interpretDisclaimerHtml = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.interpretDisclaimersTextStyle;
    const showContactSection = !(scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hideDynamicFinancingLayerContact);
    const ecomAndHideFinanceForEcom = fullVehicle.basic.buyableOnline && (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hideFinanceForEcom);
    const endpointUrl = (_b = getEnvironmentConfig()) === null || _b === void 0 ? void 0 : _b.crsServiceBaseUrl;
    const currency = (_d = (_c = getConfiguration()) === null || _c === void 0 ? void 0 : _c.currency) !== null && _d !== void 0 ? _d : audiMarketContextService === null || audiMarketContextService === void 0 ? void 0 : audiMarketContextService.getContextItem('nemo.locale.currency');
    let currencyPattern = (_f = (_e = getConfiguration()) === null || _e === void 0 ? void 0 : _e.currencyPattern) !== null && _f !== void 0 ? _f : audiMarketContextService === null || audiMarketContextService === void 0 ? void 0 : audiMarketContextService.getContextItem('stockcar.custom.currency.pattern');
    if (!currencyPattern) {
        currencyPattern = audiFetchConfigService === null || audiFetchConfigService === void 0 ? void 0 : audiFetchConfigService.getConfigurationEntryById('CurrencyPattern', appContextId);
    }
    const [webCalcErrorMsg, setWebCalcErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const svd = useSvd();
    const i18n = {
        headline: useI18n({
            id: 'nemo.ui.sc.details.finance.form.headline',
            defaultMessage: 'Finanzierungsrechner der Audi Bank',
        }),
        headlineNew: useI18n({
            id: 'nemo.ui.sc.details.finance.form.headline.new',
            defaultMessage: '',
        }),
        headlineUsed: useI18n({
            id: 'nemo.ui.sc.details.finance.form.headline.used',
            defaultMessage: '',
        }),
        productHeadline: useI18n({
            id: 'nemo.ui.sc.details.finance.form.productheadline',
            defaultMessage: 'Für welche Finanzierungsoption interessieren sie sich?',
        }),
        formHeadline: useI18n({
            id: 'nemo.ui.sc.details.finance.form.dataheadline',
            defaultMessage: 'Bitte geben Sie Ihre Finanzierungsdaten an:',
        }),
        financeRateLabelSuffix: useI18n({
            id: 'nemo.ui.sc.details.finance.form.rate.label.suffix',
            defaultMessage: '',
        }),
        calculateButton: useI18n({
            id: 'nemo.ui.sc.details.finance.form.button.calculate',
            defaultMessage: 'neu berechnen',
        }),
        updateButton: useI18n({
            id: 'nemo.ui.sc.details.finance.form.button.update',
            defaultMessage: 'aktualisieren',
        }),
        disclaimerHeadline: useI18n({
            id: 'nemo.ui.sc.details.finance.form.disclaimer',
            defaultMessage: 'Disclaimer',
        }),
        helpPopup: useI18n({
            id: 'nemo.ui.sc.details.finance.form.help.label',
            defaultMessage: 'Weitere Informationen',
        }),
        calculationError: useI18n({
            id: 'nemo.ui.sc.details.finance.form.calculationError',
            defaultMessage: 'Calculation error',
        }),
        resetButton: useI18n({
            id: 'nemo.ui.sc.details.finance.form.button.reset',
            defaultMessage: 'Reset value',
        }),
    };
    if (configureFinanceComponents.disableDynamicFinancing ||
        !((_g = fullVehicle.detail) === null || _g === void 0 ? void 0 : _g.financingRequest) ||
        ecomAndHideFinanceForEcom) {
        return null;
    }
    const financeData = useSelector((state) => SELECTORS.FINANCING.getFinancingStateRaw(state)[fullVehicle.basic.id]);
    const trackButtonClick = (buttoninfo, variant, trackingCode, btnName) => {
        trackClick(trackingService, buttoninfo, variant, 'vtp-carInfo', fullVehicle.basic.id, trackingCode, btnName);
    };
    let productParameter = {};
    let formParameter = {};
    let financeCampaign = {};
    let financingProduct = {};
    useSelector((state) => {
        productParameter = SELECTORS.FINANCING.getProductParameterForVehicleId(state, fullVehicle.basic.id);
        formParameter = SELECTORS.FINANCING.getFormParameterForVehicleId(state, fullVehicle.basic.id);
        financeCampaign = SELECTORS.FINANCING.getFinancingCampaignForVehicleId(state, fullVehicle.basic.id);
        if (financeCampaign && Object.keys(financeCampaign).length) {
            financingProduct = SELECTORS.FINANCING.getFinancingProductForProductId(state, fullVehicle.basic.id, financeCampaign.typeDetail);
        }
    });
    const requestData = financeData.vehicleData.default;
    const actualDefaultProductID = (_j = (_h = financeData === null || financeData === void 0 ? void 0 : financeData.vehicleData) === null || _h === void 0 ? void 0 : _h.default) === null || _j === void 0 ? void 0 : _j.Product['@ID'];
    const productRequestData = financeData.vehicleData.product;
    const getDynamicProduct = (result) => {
        var _a, _b, _c;
        const summary = Array.isArray(result) && result.length > 0
            ? (_b = (_a = result[0]) === null || _a === void 0 ? void 0 : _a.Summaries) === null || _b === void 0 ? void 0 : _b.Summary
            : (_c = result === null || result === void 0 ? void 0 : result.Summaries) === null || _c === void 0 ? void 0 : _c.Summary;
        const fullSummary = summary === null || summary === void 0 ? void 0 : summary.find((item) => item['@Type'] === 'Full');
        const dymanicProduct = (fullSummary === null || fullSummary === void 0 ? void 0 : fullSummary.Title['#text'])
            ? fullSummary === null || fullSummary === void 0 ? void 0 : fullSummary.Title['#text']
            : fullSummary === null || fullSummary === void 0 ? void 0 : fullSummary.Title;
        return dymanicProduct;
    };
    let initialDefaultProductData;
    if (Object.keys(financeData.defaultProductData).length > 0) {
        initialDefaultProductData = financeData.defaultProductData;
    }
    else {
        initialDefaultProductData = Array.isArray(requestData.Product)
            ? Object.assign({}, requestData.Product[0]) : Object.assign({}, requestData.Product);
    }
    const [productData, setProductData] = useState(requestData.Product);
    const [rawProduct, setRawProduct] = useState();
    const [defaultProductData, setDefaultProductData] = useState(initialDefaultProductData);
    const [formData, setFormData] = useState(null);
    const [loadedFinanceData, setLoadedFinanceData] = useState(false);
    const [inputFields, setInputFields] = useState({});
    const [showUpdateButton, setShowUpdateButton] = useState(false);
    const [dynamicHeadlineProduct, setDynamicHeadlineProduct] = useState(null);
    const [intialSetup, setIntialSetup] = useState(false);
    useEffect(() => {
        loadProductData();
    }, [defaultProductData]);
    useEffect(() => {
        if (formData && !intialSetup) {
            calculate();
            setIntialSetup(true);
        }
    }, [formData, intialSetup]);
    const setFormValue = (formName, field, value) => {
        const data = Object.assign({}, inputFields);
        data[formName][field.id] = field.control.dataType === 'decimal' ? parseFloat(value) : value;
        setInputFields(data);
    };
    const loadProductData = () => __awaiter(void 0, void 0, void 0, function* () {
        let defaultRawData;
        try {
            if (defaultProductData['@ID'] === financeData.defaultProductData['@ID'] &&
                financeData.defaultResponse.Response) {
                defaultRawData = Object.assign({}, financeData.defaultResponse.Response);
            }
            else {
                defaultRawData = yield getDefaultProduct(endpointUrl, Object.assign(Object.assign({}, requestData), {
                    Product: { '@ID': defaultProductData['@ID'] },
                }), undefined, undefined, trackingService, false);
            }
            const defaultDataForProduct = prepareDefaultDataForProduct(defaultRawData);
            const generatedFormData = generateFormData(defaultDataForProduct.Parameters, currency, currencyPattern);
            setInputFields(getFormValues(generatedFormData));
            setFormData(generatedFormData);
            setRawProduct(defaultRawData);
            setDynamicHeadlineProduct(getDynamicProduct(defaultRawData.Result));
        }
        catch (error) {
            onError(getErrorMessage(error));
        }
    });
    const loadFinanceData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _o;
        let productDataInsideFn = productData;
        try {
            setLoadedFinanceData(true);
            if (productRequestData) {
                const products = yield getProducts(endpointUrl, (_o = fullVehicle.detail.financingProductsRequest) === null || _o === void 0 ? void 0 : _o.Request, fullVehicle.basic.id, dispatch, trackingService, false);
                productDataInsideFn = products.Products.Product;
                let defaultProduct = getFSAGDefaultProduct(products);
                const storeDefaultProduct = financeData.defaultProductData;
                if (Object.keys(storeDefaultProduct).length > 0) {
                    defaultProduct = storeDefaultProduct;
                }
                if (defaultProduct['@ID'] !== defaultProductData['@ID']) {
                    setDefaultProductData(defaultProduct);
                }
            }
            patchProductData(productDataInsideFn, requestData, productParameter, defaultProductData);
            const patchedFormParameter = patchFormParameter(defaultProductData, formParameter);
            if (patchedFormParameter) {
                setDefaultProductData(patchedFormParameter);
            }
            if (financeCampaign && Object.keys(financeCampaign).length) {
                setDefaultProductData(Object.assign(Object.assign({}, defaultProductData), { '@ID': financeCampaign.typeDetail, '@CampaignID': financeCampaign.campaignId }));
            }
            yield loadProductData();
            if (financeCampaign && Object.keys(financeCampaign).length) {
                productDataInsideFn = yield addCampaignAsProduct(Array.isArray(productDataInsideFn) ? productDataInsideFn : [productDataInsideFn]);
            }
            setProductData(productDataInsideFn);
        }
        catch (error) {
            onError(getErrorMessage(error));
        }
    });
    const addCampaignAsProduct = (productDataInsideFn) => __awaiter(void 0, void 0, void 0, function* () {
        const vehiclesActiveCampaigns = yield getActiveCampaigns(fullVehicle, getEnvironmentConfig(), svd.svd, getConfiguration());
        if (!vehiclesActiveCampaigns.length) {
            resetFinancingCampaign(dispatch, fullVehicle);
        }
        let product = financingProduct;
        product = Array.isArray(product) ? product[0] : product;
        if (product && product.Label) {
            vehiclesActiveCampaigns.forEach((campaign_, index) => {
                const financeProduct = deepCopy(product);
                financeProduct.Label = campaign_.filterText;
                financeProduct.value = campaign_.typeDetail;
                financeProduct['@ID'] = `${campaign_.typeDetail}-${campaign_.campaignId}`;
                if (!productParameter || (productParameter && productParameter['@CampaignID'])) {
                    financeProduct['@Default'] =
                        vehiclesActiveCampaigns.length >= 1 &&
                            campaign_.campaignId === financeCampaign.campaignId
                            ? 'Yes'
                            : 'No';
                    if (index === 0) {
                        productDataInsideFn.forEach((prod) => {
                            prod['@Default'] = 'No';
                        });
                    }
                }
                else {
                    productDataInsideFn.forEach((prod) => {
                        prod['@Default'] = 'No';
                    });
                    const currentProduct = productDataInsideFn.filter((prod) => prod['@ID'] === productParameter['@ID']);
                    if (currentProduct.length) {
                        currentProduct[0]['@Default'] = 'Yes';
                    }
                    financeProduct['@Default'] = 'No';
                }
                financeProduct.Campaign = financeCampaign.campaignId;
                productDataInsideFn.push(financeProduct);
            });
        }
        return productDataInsideFn;
    });
    const calculate = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setWebCalcErrorMsg('');
        const formValues = Object.values(inputFields).reduce((target, form) => {
            return Object.assign(Object.assign({}, target), form);
        }, {});
        const mappedFormValues = Object.keys(formValues)
            .filter((key) => !key.startsWith('$'))
            .map((fieldId) => {
            return {
                '@ID': fieldId,
                '#text': typeof formValues[fieldId] === 'number'
                    ? formValues[fieldId]
                    : escapeRegExp(formValues[fieldId]),
            };
        });
        const product = { '@ID': defaultProductData['@ID'] };
        const request = Object.assign(Object.assign({}, requestData), { Product: Object.assign(Object.assign({}, product), { Parameter: [...mappedFormValues] }) });
        try {
            const rawProductFromApi = yield getDefaultProduct(endpointUrl, request, fullVehicle.basic.id, dispatch, trackingService, false);
            const defaultDataForProduct = prepareDefaultDataForProduct(rawProductFromApi);
            const generatedFormData = generateFormData(defaultDataForProduct.Parameters, currency, currencyPattern);
            if (rawProductFromApi.Error) {
                setWebCalcErrorMsg(rawProductFromApi.Error.Description);
                setCalculationError(true);
            }
            else {
                setInputFields(getFormValues(generatedFormData));
                setFormData(generatedFormData);
                setRawProduct(rawProductFromApi);
                setLoading(false);
                update();
                setDynamicHeadlineProduct(getDynamicProduct(rawProductFromApi.Result));
                setCalculationError(false);
            }
        }
        catch (error) {
            const errorMsg = getErrorMessage(error);
            setWebCalcErrorMsg(errorMsg);
            setLoading(false);
            setCalculationError(true);
        }
        setShowUpdateButton(true);
    });
    const update = (closeModal, trackUpdate = false) => {
        dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
            vehicleId: fullVehicle.basic.id,
            financing: { defaultResponse: { Response: rawProduct }, defaultProductData },
        }));
        if (trackUpdate)
            trackUpdateClick(trackingService, i18n.updateButton);
        if (closeModal) {
            onClose();
        }
    };
    if (!loadedFinanceData) {
        loadFinanceData();
        return null;
    }
    const renderDisclaimerText = (text) => {
        if (interpretDisclaimerHtml) {
            return React.createElement(DangerousElement, { html: text, as: "span" });
        }
        return React.createElement("span", null, text);
    };
    const handleClosePopups = () => {
        const popUps = document.querySelectorAll('div[role="tooltip"]');
        if (popUps.length > 0) {
            popUps.forEach((popup) => {
                if (popup.hasAttribute('open')) {
                    popup.style.display = 'none';
                }
                else {
                    popup.style.display = '';
                }
            });
        }
    };
    const resetFormOnClick = () => {
        setWebCalcErrorMsg('');
        loadFinanceData();
    };
    const getErrorMessage = (error) => {
        let message;
        if (error instanceof Error) {
            message = error.message;
        }
        else {
            message = String(error);
        }
        return message;
    };
    const hasAnyHelp = (formFields) => {
        return formFields === null || formFields === void 0 ? void 0 : formFields.some((form) => { var _a; return (_a = form.parameter) === null || _a === void 0 ? void 0 : _a.some((field) => { var _a; return (field === null || field === void 0 ? void 0 : field.help) && ((_a = field === null || field === void 0 ? void 0 : field.control) === null || _a === void 0 ? void 0 : _a.type) === 'Label'; }); });
    };
    const hasHelp = hasAnyHelp(formData === null || formData === void 0 ? void 0 : formData.data);
    const useStockHeadlines = i18n.headlineNew.trim() !== '' || i18n.headlineUsed.trim() !== '';
    const isNewCar = fullVehicle.basic.type.toUpperCase() === 'N';
    const stockHeadline = isNewCar ? i18n.headlineNew : i18n.headlineUsed;
    const useDynamicHeadlines = useStockHeadlines && stockHeadline !== '';
    const groupLength = (form) => {
        const filteredFields = form.parameter.filter((field) => (showSelectedProductOptionsInBreakdown || field.control.type === 'Label') &&
            (includeRateInSummaryBreakdown || field.id !== 'Rate'));
        return filteredFields.length;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadingWrapper, null,
            React.createElement(Text, { variant: "order2", as: "h2", spaceStackEnd: "xl", weight: "normal" }, useDynamicHeadlines ? `${stockHeadline} ${dynamicHeadlineProduct}` : i18n.headline)),
        React.createElement(ProductWrapper, null,
            Array.isArray(productData) && productData.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(Text, { variant: "copy1", as: "p", spaceStackEnd: "l", weight: "bold" }, i18n.productHeadline),
                React.createElement(RadioWrapper, null, productData.map((product) => (React.createElement(RadioWrapperToOverwriteLegacyCSS, { key: `${product['@ID']}` },
                    React.createElement(RadioButton, { key: `${product['@ID']}`, value: product['@ID'], checked: product['@ID'] ===
                            (Array.isArray(defaultProductData) && defaultProductData.length > 1
                                ? defaultProductData === null || defaultProductData === void 0 ? void 0 : defaultProductData.find((element) => actualDefaultProductID === element['@ID'])['@ID']
                                : defaultProductData['@ID']), inputId: product['@ID'], name: "product", onChange: () => {
                            setDefaultProductData(product);
                            setWebCalcErrorMsg('');
                        } },
                        React.createElement(React.Fragment, null, product.Label['#text'] ? product.Label['#text'] : product.Label)),
                    product.Description && (React.createElement(InfoIcon, { key: `prodct_${product.Label}`, onOpenOverlay: handleClosePopups, popupContentStyles: popupContentStyles },
                        React.createElement(DangerousElement, { html: getProductDescription(product.Description) }))))))))),
            !Array.isArray(productData) && typeof productData === 'object' && productData['Label'] && (React.createElement(React.Fragment, null,
                React.createElement(Text, { variant: "copy1", as: "p", spaceStackEnd: "l", weight: "bold" }, i18n.productHeadline),
                React.createElement(RadioWrapper, null,
                    React.createElement(RadioWrapperToOverwriteLegacyCSS, null,
                        React.createElement(RadioButton, { key: `${productData['@ID']}`, value: productData['@ID'], checked: productData['@ID'] ===
                                (Array.isArray(defaultProductData) && defaultProductData.length > 1
                                    ? defaultProductData === null || defaultProductData === void 0 ? void 0 : defaultProductData.find((element) => actualDefaultProductID === element['@ID'])['@ID']
                                    : defaultProductData['@ID']), inputId: productData['@ID'], name: "product" }, productData['Label']['#text']
                            ? productData['Label']['#text']
                            : productData['Label']),
                        productData['Description'] && (React.createElement(InfoIcon, { key: `prodct_${productData['Label']}`, onOpenOverlay: handleClosePopups, popupContentStyles: popupContentStyles },
                            React.createElement(DangerousElement, { html: getProductDescription(productData['Description']) })))))))),
        React.createElement(Text, { variant: "copy1", as: "p", spaceStackStart: "xl", spaceStackEnd: "l", weight: "bold" }, i18n.formHeadline),
        React.createElement(FormWrapper, null,
            React.createElement(FormColumnWrapper, null, (_k = formData === null || formData === void 0 ? void 0 : formData.data) === null || _k === void 0 ? void 0 : _k.map((form) => (React.createElement(React.Fragment, null, form.parameter
                .filter((field) => field.control.type !== 'Label')
                .map((field) => (React.createElement(FormElementWrapper, { key: field.id },
                field.control.type === 'Select' && (React.createElement(React.Fragment, null, field.control.style === 'Radio'
                    ? renderRadioFields(field, form.name, inputFields, setFormValue, calculate, loading)
                    : renderSelectField(field, form.name, inputFields, setFormValue, calculate, loading, trackingService))),
                field.control.type === 'Hidden' &&
                    renderHiddenField(field, form.name, inputFields),
                field.control.type === 'Edit' &&
                    renderTextField(field, form.name, inputFields, setFormValue, calculate, loading, shiftCurrencySymbolLeftToRight),
                field.hasHelp && field.control.type !== 'Label' && (React.createElement(InfoIcon, { key: `form-${field.id}`, onOpenOverlay: handleClosePopups, popupContentStyles: popupContentStyles },
                    React.createElement(DangerousElement, { html: field.help })))))))))),
            React.createElement(ReadOnlyColumn, null,
                React.createElement(ReadOnlyGroup, null, !webCalcErrorMsg ? (React.createElement(React.Fragment, null, (_l = formData === null || formData === void 0 ? void 0 : formData.data) === null || _l === void 0 ? void 0 :
                    _l.map((form) => (React.createElement(React.Fragment, null,
                        groupLength(form) > 0 && (React.createElement(Text, { variant: "copy2", weight: "bold", spaceStackEnd: "s", spaceInlineStart: "l" }, form.label)),
                        form.parameter
                            .filter((field) => (showSelectedProductOptionsInBreakdown ||
                            field.control.type === 'Label') &&
                            (includeRateInSummaryBreakdown || field.id !== 'Rate'))
                            .map((field) => (React.createElement(ReadOnlyFormElementWrapper, { key: field.id }, renderReadonlyField(field, form.name, inputFields, i18n, loading, hasHelp, handleClosePopups))))))),
                    !includeRateInSummaryBreakdown &&
                        ((_m = formData === null || formData === void 0 ? void 0 : formData.data) === null || _m === void 0 ? void 0 : _m.map((form) => (React.createElement(React.Fragment, null, form.parameter
                            .filter((field) => field.id === 'Rate')
                            .map((field) => (React.createElement(React.Fragment, null,
                            React.createElement(Divider, { spaceStackStart: "xl", spaceStackEnd: "xl", spaceInlineStart: "l", spaceInlineEnd: "l" }),
                            React.createElement(RateWrapper, null, renderRateField(field, form.name, inputFields, i18n, loading))))))))),
                    ' ')) : (React.createElement(React.Fragment, null,
                    React.createElement(WebCalcErrWrapper, null,
                        React.createElement(Text, { variant: "copy1", as: "p", spaceStackEnd: "m", weight: "bold" }, i18n.calculationError)),
                    React.createElement(WebCalcErrContainer, null,
                        React.createElement(Icon, { icon: iconCautionSmall }),
                        React.createElement(Text, { variant: "copy1", as: "p", spaceInlineStart: "m", spaceStackEnd: "m" },
                            React.createElement(DangerousElement, { as: "p", html: webCalcErrorMsg }))),
                    React.createElement(WebCalcErrWrapper, null,
                        React.createElement(Button, { variant: "text", size: "small", icon: "refresh", onClick: resetFormOnClick }, i18n.resetButton))))))),
        React.createElement(StyledButtonGroup, { variant: "block-buttons", spaceStackEnd: "xl" },
            React.createElement(FinanceCTAs, { vehicle: fullVehicle.basic, trackButtonClick: trackButtonClick }),
            showUpdateButton && (React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "secondary", spaceInlineEnd: "xxs", spaceStackEnd: "xxs", loading: loading, disabled: Boolean(webCalcErrorMsg), onClick: () => {
                        update(true, true);
                    }, "data-tracking-exclude": true }, i18n.updateButton))),
            showContactSection && (React.createElement(React.Fragment, null,
                React.createElement(FinanceModalCTA, { vehicle: fullVehicle.basic, disabled: Boolean(webCalcErrorMsg) })))),
        (formData === null || formData === void 0 ? void 0 : formData.disclaimer) && (React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "copy1", as: "p", weight: "bold" }, renderDisclaimerText(i18n.disclaimerHeadline)),
            formData.disclaimer.productDisclaimer && (React.createElement(Text, { variant: "copy1" }, renderDisclaimerText(formData.disclaimer.productDisclaimer))),
            formData.disclaimer.globalDisclaimer && (React.createElement(Text, { variant: "copy1" }, renderDisclaimerText(formData.disclaimer.globalDisclaimer)))))));
};
function getProductDescription(description) {
    if (typeof description === 'object' && '#cdata-section' in description) {
        return description['#cdata-section'];
    }
    return description;
}
