export const getVehicleRequestUrl = (scsUrlParts, vehicleId = '', svd = '') => {
    if (!scsUrlParts ||
        !scsUrlParts.scsBaseUrl ||
        !scsUrlParts.language ||
        !scsUrlParts.market ||
        !scsUrlParts.defaultVersion ||
        !vehicleId ||
        !svd) {
        return '';
    }
    const scsAPIurl = `${scsUrlParts.scsBaseUrl}${scsUrlParts.defaultVersion}/vehicles/vehicle/${scsUrlParts.language}/${scsUrlParts.market}`;
    return `${scsAPIurl}/${vehicleId}?svd=${svd}`;
};
export function generateRequestParamString(url, params = new Map()) {
    const getParams = [];
    for (const [parameterKey, parameter] of params.entries()) {
        if (parameter) {
            const encodedParameter = encodeURIComponent(parameter);
            getParams.push(`${parameterKey}=${encodedParameter}`);
        }
    }
    return `${url}${getParams.length ? `?${getParams.join('&')}` : ''}`;
}
