import React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { DangerousElement } from '../..';
const isValidToRender = (disclaimerName, hideFinancingTable, hideCalculationDisclaimer) => {
    if ((disclaimerName === 'productDisclaimer' && hideFinancingTable) ||
        (disclaimerName === 'calculationDisclaimer' && hideCalculationDisclaimer)) {
        return false;
    }
    return true;
};
export const FinanceDisclaimerMessages = ({ financing, hideFinancingTable, hideCalculationDisclaimer, interpretDisclaimersTextStyle, position, disclaimerListItems, }) => {
    return (React.createElement(React.Fragment, null, disclaimerListItems &&
        disclaimerListItems.map((disclaimerListItem, index) => {
            var _a;
            const disclaimerName = disclaimerListItem.disclaimerMsgKey;
            if (financing[disclaimerName] &&
                position === disclaimerListItem.disclaimerPositionKey &&
                isValidToRender(disclaimerName, hideFinancingTable, hideCalculationDisclaimer)) {
                return (React.createElement(Text, { key: disclaimerName, as: "p", spaceStackStart: index === disclaimerListItems.length - 1 ? 'l' : 's', spaceStackEnd: "xl", weight: "normal", variant: "copy1", "data-testid": "disclaimerText" }, interpretDisclaimersTextStyle ? (React.createElement(DangerousElement, { as: "span", html: financing[disclaimerName], sanitize: false })) : ((_a = financing[disclaimerName]) === null || _a === void 0 ? void 0 : _a.replace(/<\/?[^>]+(>|$)/g, ''))));
            }
            return null;
        })));
};
