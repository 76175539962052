import * as React from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react-v2';
import { FootnoteReference } from '../../footnotes/components/FootnoteReference';
import { DangerousElement } from '../../DangerousElement';
const RegularPriceWrap = styled.div `
  + .fa-vtp-carinfo-retail-price {
    margin-top: var(${(props) => props.theme.responsive.spacing.m});
  }
`;
export const RegularPrice = ({ vehicleType, price, showPrice, showPrices, label, footnoteLabel, showNwsDisclaimer, footnoteSuffix, }) => {
    const priceLabel = [label, price].filter((item) => !!item).join(' ');
    const carType = vehicleType === 'N' ? 'new' : 'used';
    return (React.createElement(RegularPriceWrap, null,
        !!price && showPrices && showPrice && (React.createElement(Text, { variant: "copy2", spaceStackEnd: "xs", as: "span" },
            React.createElement(DangerousElement, { html: priceLabel, as: "span" }))),
        !!price && showPrices && showPrice && showNwsDisclaimer && React.createElement("sup", null, "*"),
        !!price && showPrices && showPrice && (React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "copy1", as: "span" },
                React.createElement(FootnoteReference, { refId: `fn_price_stockcar_${carType}` })),
            React.createElement(Text, { variant: "copy1", as: "span" },
                React.createElement(FootnoteReference, { refId: `fn_regular_price_stockcar_${carType}` })),
            footnoteSuffix && React.createElement(FootnoteReference, { refId: `fn_price_regular${footnoteSuffix}` }),
            React.createElement(Text, { variant: "copy2", as: "span", spaceInlineStart: "xxs" }, footnoteLabel)))));
};
