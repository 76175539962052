import React from 'react';
import { Button } from '@audi/audi-ui-react-v2';
import { usePostData } from '../CTA';
import { useVehicleRaw } from '../../hooks';
const PostButton = ({ buttonInfo, variant, buttonSize, vehicle, trackButtonClick, isStrechedButton, showAsText, }) => {
    const { vehicleRaw } = useVehicleRaw(vehicle.id);
    const postData = typeof document !== 'undefined' && usePostData(vehicle, buttonInfo === null || buttonInfo === void 0 ? void 0 : buttonInfo.dataProfile, vehicleRaw);
    const { label, url, target, type } = buttonInfo;
    return (React.createElement("form", { method: "post", "data-testid": "post-button-form", action: url, target: target === 'new-window' ? '_blank' : '_self' },
        React.createElement(Button, { onClick: () => {
                trackButtonClick(buttonInfo, showAsText ? 'text' : variant, buttonInfo.trackingCode);
            }, spaceStackEnd: showAsText ? 'm' : 'xxs', spaceInlineEnd: showAsText ? 'xxl' : 'xxs', size: buttonSize, variant: showAsText ? 'text' : variant, "data-testid": `${type}-button-variant-${variant}`, newWindow: target === 'new-window', type: "submit", "data-tracking-exclude": true, stretch: isStrechedButton }, label),
        React.createElement("input", { type: "hidden", name: "inquiry_json_data", value: postData })));
};
export default PostButton;
