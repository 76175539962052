import React from 'react';
import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
import { Button, Text } from '@audi/audi-ui-react-v2';
import { DangerousElement } from '../../DangerousElement';
export const FinanceCheckoutLegalLayer = ({ buttonInfo, trackButtonClick, closeFocusLayer, }) => {
    const financeCheckoutLegalTranslations = {
        popoverHeading: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.legalHeading`,
        }),
        popoverContent: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.legalContent`,
        }),
        popoverCloseButton: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.close`,
        }),
    };
    const { popoverHeading, popoverContent, popoverCloseButton } = financeCheckoutLegalTranslations;
    if (!popoverContent) {
        return null;
    }
    return (React.createElement(FinanceCheckoutLegalLayerWrapper, { "data-testid": "Finance-checkout-legalLayer" },
        React.createElement(Text, { as: "h2", variant: "order4", weight: "bold", spaceStackEnd: "l" }, popoverHeading),
        React.createElement(Text, { spaceStackEnd: "l" },
            React.createElement(DangerousElement, { as: "span", html: popoverContent, sanitize: false })),
        React.createElement(FSPopoverCTASectionWrap, null,
            React.createElement(StyledButton, { variant: "secondary", onClick: () => {
                    trackButtonClick(Object.assign(Object.assign({}, buttonInfo), { label: popoverCloseButton, url: '' }), 'secondary', buttonInfo.trackingCode, 'cancel');
                    closeFocusLayer();
                }, spaceInlineEnd: "xxs", spaceStackEnd: "xxs", "data-testid": `${buttonInfo.type}-close` }, popoverCloseButton))));
};
const FinanceCheckoutLegalLayerWrapper = styled.div `
  padding: var(${(props) => props.theme.responsive.spacing.l}) 0;
`;
const FSPopoverCTASectionWrap = styled.div `
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  min-height: 55px;
  @media (max-width: 480px) {
    flex-direction: column;
    width: 98%;
  }
`;
const StyledButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  @media (max-width: 479px) {
    width: 100%;
  }
`;
