import React, { useEffect, useMemo } from 'react';

import {
  VehicleBasic,
  getVehicleIdFromUrl,
  useServicesContext,
  useVehicle,
} from '@oneaudi/vtp-shared';
import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS, SELECTORS } from '@oneaudi/stck-store';
import { useI18n } from '@oneaudi/i18n-context';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { CombinedConfig } from '../FeatureHubAppDefinition';
import { InitialTab } from '../../@types/editor.d';
import { SimilarCar, StockCarsType } from '../generated/graphql-operations';
import { parseGraphQlToVehicleBasic } from '../utills/SuggestedCarsUtills';
import { GraphQLError } from 'graphql';
import { similarCarsQuery, singleStockCarQuery } from '../queries/suggestedCarsQueries';

export const mapStockCarType = (vehicle?: VehicleBasic): StockCarsType | undefined => {
  if (vehicle?.type === 'N') {
    return StockCarsType.New;
  }
  if (vehicle?.type === 'U') {
    return StockCarsType.Used;
  }
  return undefined;
};
export interface SimilarVehiclesState {
  vehicle: VehicleBasic;
  matchingScore?: string;
}
export interface SuggestedCarsTextKeys {
  headline: string;
  similarVehicles: string;
  lastVisitedVehicles: string;
  similarVehiclesFromDealer: string;
  matchingScore: string;
  similarVehiclesDisclaimer: string;
  lastVisitedVehiclesDisclaimer: string;
  similarDealerVehiclesDisclaimer: string;
}

export function useSuggestedCars() {
  const configuration = useServicesContext().featureAppConfig as CombinedConfig;

  const [similarVehiclesState, setSimilarVehiclesState] = React.useState<SimilarVehiclesState[]>(
    []
  );
  const [LastVisitedVehiclesState, setLastVisitedVehiclesState] = React.useState<VehicleBasic[]>(
    []
  );
  const [tabState, setTabState] = React.useState<InitialTab>(
    configuration.suggestedCars.tabInitial ?? 'similarCars'
  );

  const dispatch = useDispatch();

  const { getAdditionalService } = useServicesContext();

  const apolloClient = useMemo(() => {
    const { getApolloClient } = getAdditionalService('onegraph-service') as OneGraphServiceV1;
    return getApolloClient();
  }, [getAdditionalService]);
  const localeService = getAdditionalService('gfa:locale-service') as LocaleServiceV1;
  // configuration?.vehicleId is for oneCMS as location.href used in getVehicleIdFromUrl() does not contain vehicle ID in oneCMS
  const vehicleID = (getVehicleIdFromUrl() && getVehicleIdFromUrl()) || configuration?.vehicleId!;
  const lastVisitedVehicles = useSelector((state: unknown) =>
    (SELECTORS.VEHICLES.getLastVisitedVehicleIdsState(state) as string[]).filter(
      (id) => id !== vehicleID
    )
  ) as string[];
  lastVisitedVehicles.reverse();
  const { vehicle } = useVehicle(vehicleID);
  const [stockCarType, setStockCarType] = React.useState<StockCarsType | undefined>(() =>
    mapStockCarType(vehicle)
  );

  useEffect(() => {
    setStockCarType(() => mapStockCarType(vehicle));
  }, [vehicle]);

  const TextKeyType = stockCarType === StockCarsType.New ? 'nc' : 'uc';

  const textKeys: SuggestedCarsTextKeys = {
    headline: useI18n({
      id: `nemo.ui.sc.tiles.suggested.headline.${TextKeyType}`,
      defaultMessage: 'Suggested Cars',
    }),
    similarVehicles: useI18n({
      id: `nemo.sc.similarcars.headline.${TextKeyType}`,
      defaultMessage: 'Similar cars',
    }),
    lastVisitedVehicles: useI18n({
      id: `nemo.sc.lastvisitedcars.headline.${TextKeyType}`,
      defaultMessage: 'Last visited cars',
    }),
    similarVehiclesFromDealer: useI18n({
      id: `nemo.sc.similarcarsdealer.headline.${TextKeyType}`,
      defaultMessage: 'Similar cars from this Dealer',
    }),
    matchingScore: useI18n({
      id: `nemo.ui.sc.tiles.suggested.matchingScore.${TextKeyType}`,
      defaultMessage: 'Match',
    }),
    similarVehiclesDisclaimer: useI18n({
      id: `nemo.ui.sc.tiles.suggested.disclaimer.similarVehicles.${TextKeyType}`,
      defaultMessage: '',
    }),
    lastVisitedVehiclesDisclaimer: useI18n({
      id: `nemo.ui.sc.tiles.suggested.disclaimer.lastVisitedVehicles.${TextKeyType}`,
      defaultMessage: '',
    }),
    similarDealerVehiclesDisclaimer: useI18n({
      id: `nemo.ui.sc.tiles.suggested.disclaimer.similarDealerVehicles.${TextKeyType}`,
      defaultMessage: '',
    }),
  };

  useEffect(() => {
    let mounted = true;
    if (stockCarType) {
      const getSimilarCars = async () => {
        apolloClient
          .query({
            query: similarCarsQuery,
            variables: {
              stockCarIdentifier: {
                id: vehicleID,
                stockIdentifier: {
                  marketIdentifier: {
                    brand: 'A',
                    country: localeService.countryCode,
                    language: localeService.language,
                  },
                  stockCarsType: stockCarType,
                },
              },
              similarCars: {
                maxNumberOfCars: configuration.suggestedCars.similarCars.numberOfCars,
              },
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          })
          .then((response) => {
            if (mounted) {
              const similarCars: SimilarCar[] =
                stockCarType === StockCarsType.New
                  ? response.data.stockCarExtended.similarCars?.newCars?.cars
                  : response.data.stockCarExtended.similarCars?.usedCars?.cars;
              if (similarCars) {
                const newSimilarVehicles: SimilarVehiclesState[] = [];
                similarCars.forEach((car: any) => {
                  const newSimilarVehicle = parseGraphQlToVehicleBasic(
                    localeService.countryCode,
                    localeService.language,
                    car.stockCar
                  );
                  if (newSimilarVehicle) {
                    newSimilarVehicles.push({
                      vehicle: newSimilarVehicle,
                      matchingScore: `${Math.round(car.score * 100)}%`,
                    });
                  }
                });
                setSimilarVehiclesState(newSimilarVehicles);
                dispatch(
                  ACTIONS.VEHICLES.setVehicles({
                    vehicles: newSimilarVehicles.map((similarVehicle) => similarVehicle.vehicle),
                  })
                );
              }
            }
          })
          .catch((error: GraphQLError) => {
            // eslint-disable-next-line no-console
            console.error('Couldnt find similar vehicles:', error);
          });
      };
      const getLastVisitedCars = async () => {
        const newLastVehicles: VehicleBasic[] = [];
        if (lastVisitedVehicles.length === 0) {
          if (mounted) {
            setLastVisitedVehiclesState([]);
          }
        } else {
          lastVisitedVehicles
            .slice(0, configuration.suggestedCars.previouslyViewedCars.numberOfCars ?? undefined)
            .forEach((singleVehicle) => {
              apolloClient
                .query({
                  query: singleStockCarQuery,
                  variables: {
                    stockCarIdentifier: {
                      id: singleVehicle,
                      stockIdentifier: {
                        marketIdentifier: {
                          brand: 'A',
                          country: localeService.countryCode,
                          language: localeService.language,
                        },
                        stockCarsType: stockCarType,
                      },
                    },
                  },
                  fetchPolicy: 'no-cache',
                  errorPolicy: 'ignore',
                })
                .then((response) => {
                  if (mounted) {
                    const newLastVehicle = parseGraphQlToVehicleBasic(
                      localeService.countryCode,
                      localeService.language,
                      response.data.stockCar
                    );
                    if (newLastVehicle) {
                      newLastVehicles.push(newLastVehicle);
                      dispatch(
                        ACTIONS.VEHICLES.setVehicles({
                          vehicles: [newLastVehicle],
                        })
                      );
                    }
                    setLastVisitedVehiclesState(newLastVehicles);
                  }
                })
                .catch((error: GraphQLError) => {
                  // eslint-disable-next-line no-console
                  console.error('Couldnt find single Vehicle:', error);
                });
            });
        }
      };

      getSimilarCars();
      getLastVisitedCars();
    }

    return () => {
      mounted = false;
    };
  }, [stockCarType]);

  return {
    similarVehiclesState,
    LastVisitedVehiclesState,
    tabState,
    setTabState,
    textKeys,
    lastVisitedVehicles,
  };
}
