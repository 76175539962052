import { useEffect, useState } from 'react';
export const useClientServerUtils = () => {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    }, []);
    const [forceUpdateValue, setForceUpdateValue] = useState(0);
    const forceUpdate = () => setForceUpdateValue(forceUpdateValue + 1);
    return { isClient, isServer: !isClient, forceUpdateValue, forceUpdate };
};
