import * as React from 'react';
import styled from 'styled-components';
import { Button, PopoverBody, PopoverTrigger, Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../i18n/messages';
import { useServicesContext } from '../../context';
import { FootnoteReference } from '../footnotes/components/FootnoteReference';
import { VisuallyHidden } from '../utilities';
import { Icon, iconSystemInfoSmall } from '../icon';
import { DangerousElement } from '../DangerousElement';
import { StyledInfoText } from './markets/PricesJapan';
export function createLoanTypeKey(value) {
    return `loan_type-${value.toLowerCase().replace(/\s/gi, '-')}`;
}
export const IframeWrapper = styled.div `
  width: 100%;
  height: calc(100vh - 76px);

  @media (min-width: 375px) {
    height: calc(100vh - 92px);
  }

  @media (min-width: 768px) {
    height: calc(100vh - 80px);
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 108px);
  }
`;
export const LoanLink = styled.a `
  display: inline-block;
  margin: 0 5px;
`;
export const IframeLayer = ({ layerLink }) => {
    return (React.createElement(IframeWrapper, null,
        React.createElement("iframe", { src: layerLink, width: "100%", height: "100%", title: "layer" })));
};
function openLoanTypeLinkInLayer(loanTypeLinkUrl, layerManager) {
    layerManager === null || layerManager === void 0 ? void 0 : layerManager.openFocusLayer(() => React.createElement(IframeLayer, { layerLink: loanTypeLinkUrl }), {}, {
        size: "A",
        userCloseable: true,
    });
}
export const ExceptionalFinancing = ({ financeAmount, financeLoan, loanTypeLink, vehicleType, }) => {
    const loanTypeKey = createLoanTypeKey(financeLoan.value);
    const { loanTypeLinkUrl } = loanTypeLink;
    const rateDisclaimerTooltip = useI18n({
        id: 'nemo.ui.sc.details.popover.rate.disclaimer',
        defaultMessage: '',
    });
    const carType = vehicleType === 'N' ? 'new' : 'used';
    const layerManager = useServicesContext().getAdditionalService('gfa:layer-manager');
    const handleLoanTypeClick = (e) => {
        e.preventDefault();
        openLoanTypeLinkInLayer(loanTypeLinkUrl, layerManager);
    };
    return (React.createElement("div", { "data-testid": "exceptional-financing-jp" },
        React.createElement(Text, { variant: "copy1", as: "span" },
            useI18n(messages.prices.finance.exceptional.monthlyRate),
            React.createElement(LoanLink, { href: "#", onClick: handleLoanTypeClick },
                "(",
                useI18n({
                    defaultMessage: financeLoan.value,
                    id: `nemo.ui.sc.finance.exceptional.${loanTypeKey}`,
                }),
                ")")),
        React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" },
            financeAmount.unit,
            " ",
            financeAmount.value),
        React.createElement(VisuallyHidden, null,
            React.createElement(FootnoteReference, { refId: `fn_rate_stockcar_${carType}` })),
        React.createElement(PopoverTrigger, null, (triggerProps) => (React.createElement(React.Fragment, null,
            React.createElement(Button, Object.assign({ spaceInlineStart: "l", variant: "text" }, triggerProps, { icon: React.createElement(Icon, { icon: iconSystemInfoSmall }) })),
            React.createElement(StyledInfoText, { "aria-label": rateDisclaimerTooltip },
                React.createElement(PopoverBody, null,
                    React.createElement(Text, null,
                        React.createElement(DangerousElement, { html: rateDisclaimerTooltip })))))))));
};
