import React from 'react';
import { Button } from '@audi/audi-ui-react-v2';
import { usePostData } from '../CTA';
import { openFocusLayerV2 } from '../layer/FocusLayerV2';
const NewLayerButton = ({ buttonInfo, variant, buttonSize, layerManager, trackButtonClick, configurationService, vehicle, isStrechedButton, showAsText, }) => {
    var _a;
    const iframeForms = ((_a = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes.iframeForms) || false;
    const postData = typeof document !== 'undefined' ? usePostData(vehicle, buttonInfo.dataProfile) : undefined;
    const { label, type } = buttonInfo;
    return (React.createElement(Button, { variant: showAsText ? 'text' : variant, spaceStackEnd: showAsText ? 'm' : 'xxs', spaceInlineEnd: showAsText ? 'xxl' : 'xxs', size: buttonSize, "data-testid": `${type}-button-variant-${variant}`, "data-tracking-exclude": true, stretch: isStrechedButton, onClick: () => {
            trackButtonClick(buttonInfo, showAsText ? 'text' : variant, buttonInfo.trackingCode);
            openFocusLayerV2(buttonInfo, postData, layerManager, iframeForms);
        } }, label));
};
export default NewLayerButton;
