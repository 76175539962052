import React from 'react';
import { useServicesContext } from '../../../../context';
import { GenericButton } from '../../../buttons/GenericButton';
import { useButtons } from '../../../buttons/useButtons';
const FinanceCTAs = ({ vehicle, trackButtonClick }) => {
    const layerManager = useServicesContext().getAdditionalService('gfa:layer-manager');
    const configurationService = useServicesContext().getAdditionalService('vtp-configuration-service');
    const environmentConfig = useServicesContext().getEnvironmentConfig();
    const { buttonsState, scContextId, phoneWithNumber } = useButtons(vehicle, 'carinfo', configurationService, environmentConfig, false, false);
    const ctas = buttonsState.filter((cta) => cta.showButtonInLayer);
    return (React.createElement(React.Fragment, null, ctas.map((button, index) => {
        const variant = index === 0 ? 'primary' : 'secondary';
        return (React.createElement(GenericButton, { key: `Button-vehicle-${vehicle.id}`, vehicle: vehicle, buttonInfo: button, appContext: "carinfo", variant: variant, "data-testId": `standard-button-${variant}`, layerManager: layerManager, scContextId: scContextId, phoneWithNumber: phoneWithNumber, configurationService: configurationService, trackButtonClick: trackButtonClick, isStrechedButton: false, showAsText: false, showFinancing: true }));
    })));
};
export default FinanceCTAs;
