import { useCallback } from 'react';
import { NotificationTypeV1, } from '@oneaudi/audi-notification-display-service';
export const useShareUrl = (notificationDisplayService, successNotificationMessage, successNotificationId = 'copySuccessful') => {
    return useCallback((url) => {
        if (typeof navigator !== 'undefined') {
            if (typeof navigator.share === 'function') {
                navigator.share({ url });
            }
            else {
                navigator.clipboard.writeText(url);
                if (notificationDisplayService &&
                    !notificationDisplayService.isOpen(successNotificationId) &&
                    successNotificationMessage) {
                    notificationDisplayService.createNotification(NotificationTypeV1.POPUP_SUCCESS, successNotificationId, successNotificationMessage);
                }
            }
        }
    }, [notificationDisplayService, successNotificationMessage, successNotificationId]);
};
