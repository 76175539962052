import { formatCount, useServicesContext } from '@oneaudi/vtp-shared';
import React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { TileContext } from '../hooks/tileHook';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS, SELECTORS } from '@oneaudi/stck-store';
import { ResultsBarContainer, StyledButtonText, ViewTypeContainer } from './ResultsBar.styles';
import CountAndSort from './CountAndSort';
import { trackViewChange } from './tracking';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { Button } from '@audi/audi-ui-react-v2';
import { InitialViewTypeForTheTiles } from '../../@types/editor.d';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';

const ResultsBar: React.FC = () => {
  const tileContext = React.useContext(TileContext);
  const dispatch = useDispatch();
  const listViewLabel = useI18n({ id: 'nemo.ui.sc.listView', defaultMessage: 'List view' });
  const gridViewLabel = useI18n({ id: 'nemo.ui.sc.gridView', defaultMessage: 'Grid view' });

  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const changeViewType = (viewType: InitialViewTypeForTheTiles) => {
    if (tileContext.viewType !== viewType) {
      trackViewChange(
        trackingService,
        viewType,
        viewType === 'list' ? listViewLabel : gridViewLabel
      );
    }
    tileContext.setViewType(viewType);
    dispatch(ACTIONS.UI.setResultsViewType({ resultsViewType: viewType }));
  };

  const localeService = useServicesContext().getAdditionalService(
    'gfa:locale-service'
  ) as LocaleServiceV1;
  const locale = `${localeService.language}-${localeService.countryCode}`;
  // Constants for Results
  const resultCountLabel = useI18n({ id: 'nemo.ui.sc.navig.hits', defaultMessage: '' });
  const totalResults = useSelector((state) => SELECTORS.VEHICLES.getTotalCountState(state));
  const formattedResultsCount = formatCount(totalResults, locale);
  // Constants for Favorites
  const favCountLabel = useI18n({ id: 'nemo.ui.sc.favorites', defaultMessage: '' });
  const totalFavs = useSelector((state) => SELECTORS.VEHICLES.getFavoriteVehicleIdsState(state));
  const formattedFavsCount = formatCount(totalFavs.length, locale);

  const countLabel = tileContext.appContext === 'results' ? resultCountLabel : favCountLabel;
  const formattedCount =
    tileContext.appContext === 'results' ? formattedResultsCount : formattedFavsCount;

  return (
    <ResultsBarContainer>
      <CountAndSort count={formattedCount} label={countLabel} />
      {tileContext.appContext === 'results' && (
        <ViewTypeContainer>
          <Button
            variant="text"
            data-testid="listView"
            icon="list"
            aria-label={listViewLabel}
            onClick={() => changeViewType('list')}
          >
            <StyledButtonText variant="copy2">{listViewLabel}</StyledButtonText>
          </Button>
          <Button
            variant="text"
            data-testid="gridView"
            icon="dashboard"
            aria-label={gridViewLabel}
            onClick={() => changeViewType('grid')}
          >
            <StyledButtonText variant="copy2">{gridViewLabel}</StyledButtonText>
          </Button>
        </ViewTypeContainer>
      )}
    </ResultsBarContainer>
  );
};

export default ResultsBar;
