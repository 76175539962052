import styled from 'styled-components';
export const OneCMSLayoutWrapper = styled.div `
  position: relative;
  margin: 0 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    margin: 0 28px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin: 0 40px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin: 0 60px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    margin: 0 96px;
  }
`;
