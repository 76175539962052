import React from 'react';
import { LayoutItem, Text } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { SimilarVehiclesState, SuggestedCarsTextKeys } from '../hooks/useSuggestedCars';

interface ScoreCalculationDisclaimerProps {
  tabId: string;
  isMobile?: boolean;
  similarVehicles?: SimilarVehiclesState[];
  similarVehiclesFromDealer?: SimilarVehiclesState[];
  lastVisitedVehicles?: SimilarVehiclesState[];
  textKeys: SuggestedCarsTextKeys;
}
const VehicleScoreDisclaimer: React.FC<ScoreCalculationDisclaimerProps> = ({
  tabId,
  isMobile,
  similarVehicles,
  similarVehiclesFromDealer,
  lastVisitedVehicles,
  textKeys,
}) => {
  const {
    similarVehiclesDisclaimer,
    similarDealerVehiclesDisclaimer,
    lastVisitedVehiclesDisclaimer,
  } = textKeys;
  const showSimilarVehiclesDisclaimer =
    similarVehiclesDisclaimer !== '' && similarVehicles && similarVehicles?.length > 0;
  const showsimilarVehiclesFromDealerDisclaimer =
    similarDealerVehiclesDisclaimer !== '' &&
    similarVehiclesFromDealer &&
    similarVehiclesFromDealer?.length > 0;
  const showLastVisitedVehiclesDisclaimer =
    lastVisitedVehiclesDisclaimer !== '' && lastVisitedVehicles && lastVisitedVehicles?.length > 0;
  let mobileDisclaimer: string;

  switch (tabId) {
    case 'previouslyViewedCars':
      mobileDisclaimer = lastVisitedVehiclesDisclaimer;
      break;
    case 'similarCarsFromDealer':
      mobileDisclaimer = similarDealerVehiclesDisclaimer;
      break;
    default:
      mobileDisclaimer = similarVehiclesDisclaimer;
      break;
  }

  return (similarVehiclesDisclaimer ||
    similarDealerVehiclesDisclaimer ||
    lastVisitedVehiclesDisclaimer) &&
    (isMobile ||
      showLastVisitedVehiclesDisclaimer ||
      showSimilarVehiclesDisclaimer ||
      showsimilarVehiclesFromDealerDisclaimer) ? (
    <LayoutItem spaceStackEnd="l" spaceInlineEnd="m">
      {isMobile ? (
        <MobileDisclaimer>
          <Text data-testid="mobileDisclaimer" variant="copy2" as="p">
            {mobileDisclaimer}
          </Text>
        </MobileDisclaimer>
      ) : (
        <DisclaimerContainer>
          {lastVisitedVehiclesDisclaimer !== '' && showLastVisitedVehiclesDisclaimer && (
            <Disclaimer open={tabId === 'previouslyViewedCars'} tabIndex={0}>
              <Text data-testid="previouslyViewedCars" variant="copy2" as="p">
                {lastVisitedVehiclesDisclaimer}
              </Text>
            </Disclaimer>
          )}
          {similarDealerVehiclesDisclaimer !== '' && showSimilarVehiclesDisclaimer && (
            <Disclaimer open={tabId === 'similarCarsFromDealer'} tabIndex={0}>
              <Text data-testid="similarCarsFromDealer" variant="copy2" as="p">
                {similarDealerVehiclesDisclaimer}
              </Text>
            </Disclaimer>
          )}
          {similarVehiclesDisclaimer !== '' && showSimilarVehiclesDisclaimer && (
            <Disclaimer open={tabId === 'similarCars'} tabIndex={0}>
              <Text data-testid="similarCars" variant="copy2" as="p">
                {similarVehiclesDisclaimer}
              </Text>
            </Disclaimer>
          )}
        </DisclaimerContainer>
      )}
    </LayoutItem>
  ) : null;
};
export default VehicleScoreDisclaimer;

const DisclaimerContainer = styled.span`
  display: grid;
`;
const Disclaimer = styled.span<{ open: boolean }>`
  grid-area: 1 / -1;
  visibility: ${(p) => (p.open ? 'visible' : 'hidden')};
  width: 63%;
`;
const MobileDisclaimer = styled.div`
  width: 95%;
`;
