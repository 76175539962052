import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { AudiPlatformProvider, Button, Text } from '@audi/audi-ui-react-v2';
import { useServicesContext } from '../../../context';
import { DangerousElement } from '../../DangerousElement';
import { FinanceCheckoutLegalLayer } from './FinanceCheckoutLegalLayer';
export const FinanceCheckoutLayer = ({ buttonInfo, trackButtonClick, financeCheckoutTranslations, isApiInprogress, closeFocusLayer, fsCheckoutLink, }) => {
    const layerManager = useServicesContext().getAdditionalService('gfa:layer-manager');
    const [focusLayerNode, setFocusLayerNode] = React.useState();
    const [focusLayer, setFocusLayer] = React.useState();
    const { popoverContinueButton, popoverCloseButton, popoverHeading, popoverContent, popoverLegalText, popoverLegalTextLink, } = financeCheckoutTranslations;
    const focusLayerRef = React.useCallback((node) => {
        setFocusLayerNode(node);
    }, [setFocusLayerNode]);
    const openFocusLayer = () => {
        const layer = layerManager.openFocusLayer(() => React.createElement("div", { ref: focusLayerRef }), {}, {
            size: "A",
            userCloseable: true,
            onClose: () => {
                closeFocusLegalLayer();
            },
        });
        setFocusLayer(layer);
    };
    const closeFocusLegalLayer = () => focusLayer.close();
    return (React.createElement(FinanceCheckoutLayerWrapper, { "data-testid": "finance-checkout-Layer" },
        focusLayerNode &&
            ReactDOM.createPortal(React.createElement(AudiPlatformProvider, null,
                React.createElement(FinanceCheckoutLegalLayer, { buttonInfo: buttonInfo, trackButtonClick: trackButtonClick, closeFocusLayer: closeFocusLegalLayer })), focusLayerNode),
        React.createElement(Text, { as: "h2", variant: "order4", weight: "bold", spaceStackEnd: "l" }, popoverHeading),
        React.createElement(Text, { spaceStackEnd: "l" },
            React.createElement(DangerousElement, { as: "span", html: popoverContent, sanitize: false })),
        React.createElement(FSPopoverCTASectionWrap, null,
            React.createElement(StyledButton, { variant: "secondary", onClick: () => {
                    trackButtonClick(Object.assign(Object.assign({}, buttonInfo), { label: popoverCloseButton, url: '' }), 'secondary', buttonInfo.trackingCode, 'cancel');
                    closeFocusLayer();
                }, spaceInlineEnd: "xxs", spaceStackEnd: "xxs", loading: isApiInprogress, "data-testid": `${buttonInfo.type}-close` }, popoverCloseButton),
            buttonInfo.type === 'finance-checkout' && buttonInfo.method === 'POST' && (React.createElement(React.Fragment, null,
                React.createElement(StyledButton, { href: fsCheckoutLink, onClick: () => trackButtonClick(Object.assign(Object.assign({}, buttonInfo), { label: popoverContinueButton, url: fsCheckoutLink }), 'primary', buttonInfo.trackingCode, 'continue to'), newWindow: buttonInfo.target, variant: "primary", spaceInlineEnd: "xxs", spaceStackEnd: "xxs", "data-testid": `${buttonInfo.type}-continue`, loading: isApiInprogress, "data-tracking-exclude": true }, popoverContinueButton)))),
        popoverLegalText && (React.createElement(FooterLinkWrapper, null,
            React.createElement(Text, { variant: "copy1", as: "span", spaceInlineEnd: "xs", spaceStackEnd: "m", spaceStackStart: "l", className: "popoverLegalTxt" }, popoverLegalText),
            React.createElement(Button, { id: "readmore-legal-text", variant: "text", "data-testid": "readmore-legal-text", spaceStackEnd: "m", onClick: () => {
                    openFocusLayer();
                }, className: "popoverLegalBtn", "data-tracking-exclude": true },
                React.createElement(Text, { as: "span", variant: "copy1" }, popoverLegalTextLink))))));
};
const FinanceCheckoutLayerWrapper = styled.div `
  padding: var(${(props) => props.theme.responsive.spacing.l}) 0;
`;
const FSPopoverCTASectionWrap = styled.div `
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  min-height: 55px;
  @media (max-width: 480px) {
    flex-direction: column;
    width: 98%;
  }
`;
const StyledButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  @media (max-width: 479px) {
    width: 100%;
  }
`;
const FooterLinkWrapper = styled.div `
  margin: var(${(props) => props.theme.responsive.spacing.l}) 0;
  display: flex;
  align-items: center;

  .popoverLegalTxt {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .popoverLegalBtn {
    margin-bottom: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.m}px) {
    flex-direction: column;
    align-items: flex-start;

    .popoverLegalTxt {
      margin-bottom: var(${(props) => props.theme.responsive.spacing.s});
    }
  }
`;
