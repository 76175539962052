import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import Tile from './components/Tile';
import Tiles from './components/Tiles';
import { TileContext, TileContextState } from './hooks/tileHook';
import { Divider } from '@audi/audi-ui-react-v2';
import ResultsBar from './components/ResultsBar';
import { useServicesContext, VehicleBasic } from '@oneaudi/vtp-shared';
import LoadMoreButton from './components/LoadMoreButton';
import ZeroResultsPage from './components/ZeroResultsPage';
import { CombinedConfig } from './FeatureHubAppDefinition';

interface Props {}

const Results: React.FC<Props> = () => {
  const resultVehicles: VehicleBasic[] = Array.from(
    useSelector((state) => SELECTORS.VEHICLES.getResultVehiclesMap(state)).values()
  );

  const configuration = useServicesContext().featureAppConfig as CombinedConfig;
  const storeViewType = useSelector((state) => SELECTORS.UI.getResultsViewType(state));
  let initialViewType = storeViewType;
  if (initialViewType === '' || (initialViewType !== 'list' && initialViewType !== 'grid')) {
    initialViewType = configuration?.viewType === 'list' ? 'list' : 'grid';
  }
  const [viewType, setViewType] = React.useState(initialViewType);

  const tileContextValue: TileContextState = useMemo(() => {
    return {
      viewType,
      setViewType,
      appContext: 'results',
      contentConfiguration: configuration,
    };
  }, [viewType, configuration]);

  return (
    <TileContext.Provider value={tileContextValue}>
      <ResultsBar />
      <Divider spaceStackStart="l" />
      {resultVehicles.length === 0 ? (
        <ZeroResultsPage context="results" />
      ) : (
        <>
          <Tiles>
            {resultVehicles.map((resultVehicle) => (
              <Tile key={resultVehicle.id} vehicle={resultVehicle} />
            ))}
          </Tiles>
          <LoadMoreButton />
        </>
      )}
    </TileContext.Provider>
  );
};

export default Results;
