import React from 'react';
import { LayoutItem, Text } from '@audi/audi-ui-react-v2';
import Tile from './Tile';
import { useServicesContext, Slider } from '@oneaudi/vtp-shared';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { trackSliderChange } from './tracking';
import VehicleScoreDisclaimer from './VehicleScoreDisclaimer';
import { SimilarVehiclesState, SuggestedCarsTextKeys } from '../hooks/useSuggestedCars';

export interface SuggestedSliderProps {
  suggestedVehicles: SimilarVehiclesState[];
  tabText: string;
  tabId: string;
  showSlider: boolean;
  isMobile: boolean;
  textKeys: SuggestedCarsTextKeys;
}

const SuggestedSlider: React.FC<SuggestedSliderProps> = ({
  suggestedVehicles,
  tabId,
  tabText,
  showSlider,
  isMobile,
  textKeys,
}) => {
  const hasVehicles = suggestedVehicles.length > 0;
  const showTab = isMobile || showSlider;
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const onChangeHandler = (elementName: 'text link' | 'arrow') => {
    trackSliderChange(trackingService, elementName);
  };

  return hasVehicles && showTab ? (
    <LayoutItem spaceStackEnd="xxl">
      {isMobile && (
        <>
          <Text variant="order4" as="h4" spaceStackEnd="l" spaceStackStart="xxl">
            {tabText}
          </Text>
          <VehicleScoreDisclaimer tabId={tabId} isMobile textKeys={textKeys} />
        </>
      )}
      <Slider onChangeHandler={onChangeHandler} isSuggestedCars>
        {suggestedVehicles &&
          suggestedVehicles.map((suggestedVehicle) => {
            return (
              <Tile
                key={`${suggestedVehicle}-suggested`}
                vehicle={suggestedVehicle.vehicle}
                matchingScore={suggestedVehicle.matchingScore}
              />
            );
          })}
      </Slider>
    </LayoutItem>
  ) : null;
};
export default SuggestedSlider;
