import * as React from 'react';
import styled from 'styled-components';
import { Pagination as PaginationComponent, PaginationVariant } from '@audi/audi-ui-react-v2';
import { getDeviceType, NUMBER_OF_DESKTOP_TILES, NUMBER_OF_TABLET_TILES } from './Slider';
const Pagination = (props) => {
    const { length, currentIndex, updateIndex } = props;
    const desktop = getDeviceType() === 'desktop';
    const tablet = getDeviceType() === 'tablet';
    const getCount = () => desktop
        ? Math.ceil(length / NUMBER_OF_DESKTOP_TILES)
        : Math.ceil(length / NUMBER_OF_TABLET_TILES);
    const calculateNewIndex = (index) => desktop
        ? index * NUMBER_OF_DESKTOP_TILES - NUMBER_OF_DESKTOP_TILES
        : index * NUMBER_OF_TABLET_TILES - NUMBER_OF_TABLET_TILES;
    const getPageNumber = () => desktop
        ? Math.ceil((currentIndex + 1) / NUMBER_OF_DESKTOP_TILES)
        : Math.ceil((currentIndex + 1) / NUMBER_OF_TABLET_TILES);
    const onChange = (index) => {
        updateIndex(desktop || tablet ? calculateNewIndex(index) : index - 1);
    };
    return (React.createElement(Container, null,
        React.createElement(PaginationComponent, { count: desktop || tablet ? getCount() : length, onChange: onChange, page: desktop || tablet ? getPageNumber() : currentIndex + 1, variant: desktop || tablet ? PaginationVariant.normal : PaginationVariant.compact, "data-testid": "pagination" })));
};
const Container = styled.div `
  display: flex;
  justify-content: center;
  padding: var(${(props) => props.theme.responsive.spacing.xl}) 0
    var(${(props) => props.theme.responsive.spacing.xxl});

  @media screen and (min-width: 768px) {
    padding: var(${(props) => props.theme.responsive.spacing.xxl}) 0 0;
  }
`;
export default Pagination;
