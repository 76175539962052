import * as React from 'react';
import DOMPurify from 'dompurify';
import { useClientServerUtils } from '../hooks';
export const sanitizeHTML = (htmlString) => 'sanitize' in DOMPurify ? DOMPurify.sanitize(htmlString) : '';
export const DangerousElement = ({ html, as = 'div', sanitize = true, }) => {
    const { isClient } = useClientServerUtils();
    if (!isClient) {
        return React.createElement(as, {}, html);
    }
    return React.createElement(as, {
        dangerouslySetInnerHTML: { __html: sanitize ? sanitizeHTML(html) : html },
    });
};
