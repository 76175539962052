import * as React from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../i18n/messages';
import { FootnoteReference } from '..';
import { DangerousElement } from '../DangerousElement';
import { getActualTestCycles, getEfficiencyClass, getElectricalData, getTilesEmissionRepresentation, isPHEV, } from '../../utils';
import { useTilesConsumptionLabels, useTilesEmissionLabels } from '../../hooks';
import { TestCycleTypesFromConfig, } from '@oneaudi/vtp-configuration-service';
const ConsumptionItem = styled.div `
  display: flex;
  margin-bottom: var(${(props) => props.theme.responsive.spacing.s});
`;
const ItemLabel = styled.div `
  margin-top: 2px;
`;
export const ConsumptionTileItem = ({ vehicle, type }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const efficiencyLabel = useI18n(messages.consumptionTileElement.efficiency);
    const efficiencyClass = getEfficiencyClass(vehicle);
    const [primaryConsumptionLabel, secondaryConsumptionLabel] = useTilesConsumptionLabels(vehicle);
    const [primaryEmissionValue, secondaryEmissionValue] = useTilesEmissionLabels(vehicle);
    const consumptionCombinedLabel = useI18n(messages.consumptionTileElement.consumptionCombined);
    const emissionCombinedLabel = useI18n(messages.consumptionTileElement.emissionCombined);
    const electricalData = getElectricalData(vehicle);
    const rangeLabel = useI18n(messages.consumptionTileElement.electricalRangeCombined);
    const rangeData = (_c = (_b = (_a = electricalData === null || electricalData === void 0 ? void 0 : electricalData.range) === null || _a === void 0 ? void 0 : _a.wltp) === null || _b === void 0 ? void 0 : _b.dataSets) === null || _c === void 0 ? void 0 : _c.find((dataset) => {
        return dataset.isDefault === true;
    });
    const rangeValue = ((_d = rangeData === null || rangeData === void 0 ? void 0 : rangeData.values) === null || _d === void 0 ? void 0 : _d.combined)
        ? `${(_e = rangeData === null || rangeData === void 0 ? void 0 : rangeData.values) === null || _e === void 0 ? void 0 : _e.combined} ${(_g = (_f = electricalData === null || electricalData === void 0 ? void 0 : electricalData.range) === null || _f === void 0 ? void 0 : _f.wltp) === null || _g === void 0 ? void 0 : _g.unit}`
        : '';
    const showEmissionRepresentationAccordingToCOC6 = vehicle.noNedc;
    const emissionRepresentation = showEmissionRepresentationAccordingToCOC6
        ? [TestCycleTypesFromConfig.WLTP]
        : getTilesEmissionRepresentation();
    const actualTestCycles = getActualTestCycles(vehicle, emissionRepresentation);
    const isMultiFuel = isPHEV(vehicle);
    return (React.createElement(React.Fragment, null,
        type === 'efficiency' && efficiencyClass && !showEmissionRepresentationAccordingToCOC6 && (React.createElement(ConsumptionItem, { "data-testid": "result-tile-efficiency" },
            React.createElement(ItemLabel, null,
                React.createElement(Text, { variant: "copy2" },
                    React.createElement(DangerousElement, { html: `${efficiencyLabel}: `, as: "span", "data-testid": "efficiency-label" }),
                    efficiencyClass)))),
        type === 'consumption' && (React.createElement(ConsumptionItem, { "data-testid": "result-tile-consumption" },
            React.createElement(ItemLabel, null,
                React.createElement(Text, { variant: "copy2" },
                    React.createElement("span", { "data-testid": "consumption-label-primary" },
                        !isMultiFuel && (React.createElement(React.Fragment, null,
                            `${consumptionCombinedLabel}: ${primaryConsumptionLabel}`,
                            React.createElement(FootnoteReference, { refId: `fn_${(_h = actualTestCycles[0]) === null || _h === void 0 ? void 0 : _h.toLowerCase()}_stockcar` }))),
                        isMultiFuel && (React.createElement(React.Fragment, null,
                            `${consumptionCombinedLabel}: ${primaryConsumptionLabel === null || primaryConsumptionLabel === void 0 ? void 0 : primaryConsumptionLabel.split(';')[0]}`,
                            React.createElement(FootnoteReference, { refId: `fn_${(_j = actualTestCycles[0]) === null || _j === void 0 ? void 0 : _j.toLowerCase()}_stockcar` }),
                            ";\u00A0", (_k = primaryConsumptionLabel === null || primaryConsumptionLabel === void 0 ? void 0 : primaryConsumptionLabel.split(';')[1]) === null || _k === void 0 ? void 0 :
                            _k.trim(),
                            React.createElement(FootnoteReference, { refId: `fn_${(_l = actualTestCycles[1]) === null || _l === void 0 ? void 0 : _l.toLowerCase()}_stockcar` })))),
                    !!secondaryConsumptionLabel && (React.createElement(React.Fragment, null,
                        ` | `,
                        !isMultiFuel && (React.createElement("span", { "data-testid": "consumption-label-secondary" },
                            secondaryConsumptionLabel,
                            React.createElement(FootnoteReference, { refId: `fn_${(_m = actualTestCycles[1]) === null || _m === void 0 ? void 0 : _m.toLowerCase()}_stockcar` }))),
                        isMultiFuel && (React.createElement("span", { "data-testid": "consumption-label-secondary" }, secondaryConsumptionLabel === null || secondaryConsumptionLabel === void 0 ? void 0 :
                            secondaryConsumptionLabel.split(';')[0],
                            React.createElement(FootnoteReference, { refId: `fn_${(_o = actualTestCycles[0]) === null || _o === void 0 ? void 0 : _o.toLowerCase()}_stockcar` }),
                            ";\u00A0", (_p = secondaryConsumptionLabel === null || secondaryConsumptionLabel === void 0 ? void 0 : secondaryConsumptionLabel.split(';')[1]) === null || _p === void 0 ? void 0 :
                            _p.trim(),
                            React.createElement(FootnoteReference, { refId: `fn_${(_q = actualTestCycles[1]) === null || _q === void 0 ? void 0 : _q.toLowerCase()}_stockcar` }))))))))),
        type === 'emission' && (React.createElement(ConsumptionItem, { "data-testid": "result-tile-emission" },
            React.createElement(ItemLabel, null,
                React.createElement(Text, { variant: "copy2" },
                    React.createElement("span", { "data-testid": "emission-label-primary" },
                        `${emissionCombinedLabel}: ${primaryEmissionValue}`,
                        React.createElement(FootnoteReference, { refId: `fn_${(_r = actualTestCycles[0]) === null || _r === void 0 ? void 0 : _r.toLowerCase()}_stockcar` })),
                    !!secondaryEmissionValue && !showEmissionRepresentationAccordingToCOC6 && (React.createElement(React.Fragment, null,
                        ` | `,
                        React.createElement("span", { "data-testid": "emission-label-secondary" },
                            secondaryEmissionValue,
                            React.createElement(FootnoteReference, { refId: `fn_${(_s = actualTestCycles[1]) === null || _s === void 0 ? void 0 : _s.toLowerCase()}_stockcar` })))))))),
        type === 'electrical-range' && rangeValue !== '' && (React.createElement(ConsumptionItem, { "data-testid": "result-tile-electrical-range" },
            React.createElement(ItemLabel, null,
                React.createElement(Text, { variant: "copy2" },
                    rangeLabel,
                    ": ",
                    rangeValue))))));
};
