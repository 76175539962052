import React from 'react';
import { Divider, SkeletonShape } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import {
  CountAndSortContainer,
  ResultsBarContainer,
  ViewTypeContainer,
} from '../ResultsBar.styles';
import { Container as LoadMoreButtonContainer } from '../LoadMoreButton.styles';
import { GridTilesContainer, ListTilesContainer } from '../Tiles.styles';
import { TileSkeleton } from './TileSkeleton';
import { useServicesContext } from '@oneaudi/vtp-shared';
import { CombinedConfig } from '../../FeatureHubAppDefinition';

const ResultsBar = styled.div`
  display: flex;
`;

const LoadMoreButton = styled.div`
  display: inline-flex;
`;

interface Props {}

export const ResultsSkeleton: React.FC<Props> = () => {
  const configurationTiles = useServicesContext().featureAppConfig as CombinedConfig;
  const viewType = configurationTiles.viewType === 'list' ? 'list' : 'grid';
  const TilesContainer = viewType === 'grid' ? GridTilesContainer : ListTilesContainer;

  return (
    <>
      <ResultsBarContainer>
        <CountAndSortContainer>
          <SkeletonShape variant="rectangular" height="32px" width="370px" spaceStackEnd="l" />
          <SkeletonShape variant="rectangular" height="20px" width="205px" />
          <ResultsBar>
            <SkeletonShape variant="rectangular" height="37px" width="305px" />
            <SkeletonShape
              variant="circular"
              height="24px"
              width="24px"
              spaceInlineStart="l"
              spaceStackStart="xs"
            />
          </ResultsBar>
        </CountAndSortContainer>
        <ViewTypeContainer>
          <SkeletonShape variant="rectangular" height="24px" width="200px" />
        </ViewTypeContainer>
      </ResultsBarContainer>
      <Divider spaceStackStart="l" />
      <TilesContainer>{new Array(12).fill(<TileSkeleton viewType={viewType} />)}</TilesContainer>
      <LoadMoreButtonContainer>
        <LoadMoreButton>
          <SkeletonShape variant="rectangular" height="56px" width="200px" />
        </LoadMoreButton>
      </LoadMoreButtonContainer>
    </>
  );
};
