(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@audi/audi-ui-react-v2"), require("@feature-hub/react"), require("@oneaudi/onegraph-client"), require("react"), require("react-dom"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["@audi/audi-ui-react-v2", "@feature-hub/react", "@oneaudi/onegraph-client", "react", "react-dom", "styled-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@audi/audi-ui-react-v2"), require("@feature-hub/react"), require("@oneaudi/onegraph-client"), require("react"), require("react-dom"), require("styled-components")) : factory(root["@audi/audi-ui-react-v2"], root["@feature-hub/react"], root["@oneaudi/onegraph-client"], root["react"], root["react-dom"], root["styled-components"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__2053__, __WEBPACK_EXTERNAL_MODULE__9544__, __WEBPACK_EXTERNAL_MODULE__4134__, __WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__9514__, __WEBPACK_EXTERNAL_MODULE__8694__) => {
return 