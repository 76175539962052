import { useEffect, useState } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { getEmissionLabels, getTilesEmissionRepresentation } from '../../utils';
import { messages } from '../../i18n/messages';
import { TestCycleTypesFromConfig, } from '@oneaudi/vtp-configuration-service';
export const useTilesEmissionLabels = (vehicle) => {
    const [emissionValues, setEmissionValues] = useState(['', '']);
    const wltp = useI18n(messages.drivingCycles.wltp.label);
    const nedc = useI18n(messages.drivingCycles.nedc.label);
    const emissionRepresentation = vehicle.noNedc
        ? [TestCycleTypesFromConfig.WLTP]
        : getTilesEmissionRepresentation();
    useEffect(() => {
        if (vehicle) {
            const testCycleLabels = {
                wltp,
                nedc,
            };
            const emissionLabels = getEmissionLabels(vehicle, emissionRepresentation, testCycleLabels);
            if (emissionValues[0] !== emissionLabels[0] || emissionValues[1] !== emissionLabels[1]) {
                setEmissionValues(emissionLabels);
            }
        }
    }, [emissionRepresentation, nedc, vehicle, wltp, emissionValues]);
    return emissionValues;
};
