import * as React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../../i18n/messages';
export const OptionsPrice = ({ vehicle }) => {
    const optionsPrice = vehicle.typedPrices.find((typepPrice) => {
        return typepPrice.type === 'options';
    });
    return (React.createElement(React.Fragment, null, optionsPrice && (React.createElement(Text, { variant: "copy2", "data-testid": "optionPrice", as: "span", spaceStackEnd: "xxl" },
        useI18n(messages.prices.options),
        "\u00A0",
        optionsPrice.formatted))));
};
