import React, { useEffect, useMemo, useState } from 'react';
import GalleryImage from './GalleryImage';
import {
  ImageSlider,
  VehicleBasic,
  useServicesContext,
  getRelatedProduct,
} from '@oneaudi/vtp-shared';
import { Pagination } from '@audi/audi-ui-react-v2';
import { Container, StyledPagination } from './Gallery.styles';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { trackDetailsPageNav, trackImageChange } from '../tracking';
import { getContentConfiguration } from '../../hooks/tileHook';

export interface GalleryProps {
  vehicle: VehicleBasic;
  detailsPageLink: string;
  isListView: boolean;
}

const Gallery: React.FC<GalleryProps> = ({ vehicle, detailsPageLink, isListView }) => {
  const [currentSlideIndex, setSlideIndex] = useState(0);
  const [trackSwipe, setTrackSwipe] = useState(false);
  const alternativeText = vehicle.symbolicCarline.description;
  const contentConfiguration = getContentConfiguration();

  const fallbackImage = useMemo(() => {
    return vehicle.fallbackPictures?.find((picture) => picture.key === 'render_4x3');
  }, [vehicle.fallbackPictures]);

  const pictures = useMemo(() => {
    const result = [];
    const dealerImages = vehicle.pictures?.filter((picture) =>
      picture.originalKey.startsWith('dealer-images')
    );

    const renderImages = vehicle.tilesPictures;

    const showDealerImages = !vehicle.nationWideSelling && dealerImages && dealerImages.length > 0;
    const showRenderImages =
      vehicle.type !== 'U' && !vehicle.hideRenderImages && renderImages && renderImages.length > 0;

    if (showRenderImages) {
      result.push(...renderImages);
    }

    if (showDealerImages) {
      result.push(...dealerImages);
    }

    if (!result.length) {
      if (renderImages && renderImages.length > 0) {
        result.push(...renderImages);
      } else {
        result.push(fallbackImage);
      }
    }
    return result;
  }, [vehicle.pictures, fallbackImage]);

  const relatedProduct = getRelatedProduct(vehicle);
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;

  const trackSlideUpdate = (
    slide: number,
    elementName: 'arrow' | 'image' | 'other',
    actionType: 'swipe' | 'click' | 'automatic'
  ) => {
    const position =
      currentSlideIndex < slide
        ? `${currentSlideIndex + 1}~${currentSlideIndex + 2}`
        : `${currentSlideIndex + 1}~${currentSlideIndex}`;
    trackImageChange(
      trackingService,
      elementName,
      `${currentSlideIndex + 1}`,
      position,
      relatedProduct,
      actionType,
      contentConfiguration?.appContext
    );
  };
  const onChangeHandler = (slide: number) => {
    setSlideIndex(slide - 1);
    trackSlideUpdate(slide, 'arrow', 'click');
  };
  const updateSlideAndTrack = (slide: number) => {
    setSlideIndex(slide);
    if (trackSwipe && slide !== currentSlideIndex) {
      trackSlideUpdate(slide, 'image', 'swipe');
    }
  };
  useEffect(() => {
    setTrackSwipe(true);
  }, [trackSwipe]);

  return (
    <Container>
      {pictures.length > 1 && (
        <>
          <a
            href={detailsPageLink}
            onClick={(evt: any) => {
              if (evt.target.nodeName === 'IMG') {
                trackDetailsPageNav(
                  trackingService,
                  '',
                  detailsPageLink,
                  'image',
                  contentConfiguration?.appContext
                );
              }
            }}
          >
            <ImageSlider
              disableButtonNavigation
              updatedSlide={(slide: number) => {
                updateSlideAndTrack(slide);
              }}
              slide={currentSlideIndex}
              data-testid="imageSlider"
            >
              {pictures.map((picture, index) => (
                <GalleryImage
                  picture={picture!}
                  alt={alternativeText}
                  key={picture!.key}
                  active={index === currentSlideIndex}
                  renderPicture={index >= currentSlideIndex - 1 && index <= currentSlideIndex + 1}
                />
              ))}
            </ImageSlider>
          </a>
          <StyledPagination isListView={isListView}>
            <Pagination
              data-testid="pagination"
              count={pictures.length}
              onChange={(slide: number) => onChangeHandler(slide)}
              page={currentSlideIndex + 1}
              variant="compact"
            />
          </StyledPagination>
        </>
      )}
      {pictures.length === 1 && (
        <>
          <a
            href={detailsPageLink}
            onClick={(evt: any) => {
              if (evt.target.nodeName === 'IMG') {
                trackDetailsPageNav(trackingService, '', detailsPageLink, 'image');
              }
            }}
          >
            <GalleryImage picture={pictures[0]!} alt={alternativeText} />
          </a>
          <StyledPagination isListView={isListView} pictureLength={pictures.length} />
        </>
      )}
      {pictures.length === 0 && fallbackImage && (
        <>
          <a
            href={detailsPageLink}
            onClick={(evt: any) => {
              if (evt.target.nodeName === 'IMG') {
                trackDetailsPageNav(trackingService, '', detailsPageLink, 'image');
              }
            }}
          >
            <GalleryImage picture={fallbackImage} alt={alternativeText} />
          </a>
          <StyledPagination isListView={isListView} pictureLength={pictures.length} />
        </>
      )}
    </Container>
  );
};

export default Gallery;
