import React from 'react';
import PostButton from './PostButton';
import GetButton from './GetButton';
import { FinanceCheckoutPopoverButton } from './FinanceCheckoutPopoverButton';
import NewLayerButton from './NewLayerButton';
import { Button } from '@audi/audi-ui-react-v2';
import { PhoneButton } from './PhoneButton';
export const GenericButton = ({ vehicle, appContext, buttonInfo, variant, phoneWithNumber, configurationService, layerManager, scContextId, trackButtonClick, isStrechedButton, showAsText, showFinancing, }) => {
    const { label, url, target } = buttonInfo;
    const buttonSize = appContext === 'carinfo' ? 'medium' : 'small';
    if (buttonInfo.type === 'phone' || buttonInfo.type === 'central-customer-hotline') {
        return (React.createElement(PhoneButton, { buttonInfo: buttonInfo, variant: variant, trackButtonClick: trackButtonClick, isStrechedButton: isStrechedButton, showAsText: showAsText, buttonSize: buttonSize, appContext: appContext, phoneWithNumber: phoneWithNumber }));
    }
    if (buttonInfo.target === 'same-window' || buttonInfo.target === 'new-window') {
        if (buttonInfo.method === 'POST') {
            return (React.createElement(PostButton, { buttonInfo: buttonInfo, variant: variant, buttonSize: buttonSize, vehicle: vehicle, trackButtonClick: trackButtonClick, isStrechedButton: isStrechedButton, showAsText: showAsText }));
        }
        if (buttonInfo.method === 'GET') {
            return (React.createElement(GetButton, { buttonInfo: buttonInfo, variant: variant, buttonSize: buttonSize, trackButtonClick: trackButtonClick, isStrechedButton: isStrechedButton, showAsText: showAsText }));
        }
    }
    if (showFinancing && buttonInfo.type === 'finance-checkout') {
        return (React.createElement(FinanceCheckoutPopoverButton, { buttonInfo: buttonInfo, vehicle: vehicle, variant: variant, buttonSize: buttonSize, trackButtonClick: trackButtonClick, scContextId: scContextId, showAsText: showAsText, showFinancingbuttonInfo: showFinancing }));
    }
    if (buttonInfo.target === 'open-in-layer') {
        return (React.createElement(NewLayerButton, { buttonInfo: buttonInfo, variant: variant, buttonSize: buttonSize, layerManager: layerManager, configurationService: configurationService, trackButtonClick: trackButtonClick, vehicle: vehicle, isStrechedButton: isStrechedButton, showAsText: showAsText }));
    }
    return (React.createElement(Button, { variant: showAsText ? 'text' : variant, size: buttonSize, href: url, target: target, stretch: isStrechedButton, spaceStackEnd: showAsText ? 'm' : 'xxs', spaceInlineEnd: showAsText ? 'xxl' : 'xxs', onClick: () => {
            trackButtonClick(buttonInfo, showAsText ? 'text' : variant, buttonInfo.trackingCode);
        } }, label));
};
