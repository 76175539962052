import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { audiDarkTheme, Button, Text, ThemeProvider } from '@audi/audi-ui-react-v2';
import { ACTIONS, SELECTORS } from '@oneaudi/stck-store';
import { useI18n } from '@oneaudi/i18n-context';
import { Icon, VehicleBasic, getRelatedProduct, useServicesContext } from '@oneaudi/vtp-shared';
import { getAppContext, getContentConfiguration, isListView } from '../hooks/tileHook';
import { favorites, favoritesActive } from './icons';
import {
  FavoritesContainer,
  FavoritesOverlay,
  GridButtonContainer,
  GridContainer,
  ListButtonContainer,
  ListContainer,
} from './ActionButtons.styles';
import { trackAddToFav, trackRemoveFromFav } from './tracking';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';

interface Props {
  vehicle: VehicleBasic;
}

const ActionButtons: React.FC<Props> = ({ vehicle }) => {
  const addFavoriteLabel = useI18n({ id: 'nemo.ui.sc.result.addfavorite', defaultMessage: '' });
  const removeFavoriteLabel = useI18n({
    id: 'nemo.ui.sc.result.removefavorite',
    defaultMessage: '',
  });
  const favoritesLayerHeadline = useI18n({
    id: 'nemo.ui.sc.favorites.layer.headline',
    defaultMessage: '',
  });
  const favoritesLayerCopy = useI18n({ id: 'nemo.ui.sc.favorites.layer.copy', defaultMessage: '' });
  const favoritesLayerYes = useI18n({
    id: 'nemo.ui.sc.favorites.layer.button.yes',
    defaultMessage: 'Yes',
  });
  const favoritesLayerNo = useI18n({
    id: 'nemo.ui.sc.favorites.layer.button.no',
    defaultMessage: 'No',
  });

  const appContext = getAppContext();
  const dispatch = useDispatch();
  const contentConfiguration = getContentConfiguration();
  const isFavorite = useSelector((state) =>
    SELECTORS.VEHICLES.isFavoriteVehicle(state, vehicle.id)
  );
  const [showFavoritesLayer, setShowFavoritesLayer] = useState(false);

  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const relatedProduct = getRelatedProduct(vehicle);
  const handleFavoritesClick = () => {
    if (isFavorite) {
      if (appContext !== 'results') {
        setShowFavoritesLayer(true);
      } else {
        removeFavorites();
      }
    } else {
      dispatch(
        ACTIONS.FAVORITE_VEHICLES.addFavoriteVehicleId({
          vehicleId: vehicle.id,
        })
      );
      trackAddToFav(
        trackingService,
        addFavoriteLabel,
        relatedProduct,
        contentConfiguration?.appContext
      );
    }
  };
  const removeFavorites = () => {
    dispatch(
      ACTIONS.FAVORITE_VEHICLES.removeFavoriteVehicleId({
        vehicleId: vehicle.id,
      })
    );
    setShowFavoritesLayer(false);
    trackRemoveFromFav(
      trackingService,
      removeFavoriteLabel,
      relatedProduct,
      contentConfiguration?.appContext
    );
  };

  const Container = isListView() ? ListContainer : GridContainer;
  const ButtonContainer = isListView() ? ListButtonContainer : GridButtonContainer;

  return (
    <>
      <Container>
        <FavoritesContainer>
          <Button
            variant="icon-tertiary"
            size="small"
            icon={<Icon icon={isFavorite ? favoritesActive : favorites} />}
            onClick={handleFavoritesClick}
            data-testid="favoritesBtn"
          />
        </FavoritesContainer>
      </Container>
      {showFavoritesLayer && (
        <ThemeProvider theme={audiDarkTheme}>
          <FavoritesOverlay>
            <Text variant="order3" weight="bold" spaceStackEnd="m">
              {favoritesLayerHeadline}
            </Text>
            <Text variant="copy2" spaceStackEnd="xl">
              {favoritesLayerCopy}
            </Text>
            <ButtonContainer data-testid="removeFavoriteLayer">
              <Button
                variant="primary"
                spaceStackEnd="xxs"
                onClick={removeFavorites}
                data-testid="removeFavorite"
              >
                {favoritesLayerYes}
              </Button>
              <Button
                variant="secondary"
                onClick={() => setShowFavoritesLayer(false)}
                data-testid="closeRemoveFavoriteLayer"
              >
                {favoritesLayerNo}
              </Button>
            </ButtonContainer>
          </FavoritesOverlay>
        </ThemeProvider>
      )}
    </>
  );
};

export default ActionButtons;
