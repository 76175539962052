import * as React from 'react';
import styled from 'styled-components';
import { NUMBER_OF_DESKTOP_TILES, NUMBER_OF_TABLET_TILES, getDeviceType, } from './Slider';
import { CoreCiIcon } from '../coreCiIcon/CoreCiIcon';
export const ArrowTileContainer = (props) => {
    const { updateIndex, currentIndex, children, length, isSuggestedCars } = props;
    const desktop = getDeviceType() === 'desktop';
    const tablet = getDeviceType() === 'tablet';
    if (getDeviceType() === 'mobile') {
        return React.createElement(React.Fragment, null, children);
    }
    const calculateNewIndex = (index, deviceType, delta) => {
        switch (deviceType) {
            case 'desktop':
                return index + delta * NUMBER_OF_DESKTOP_TILES;
            case 'tablet':
                return index + delta * NUMBER_OF_TABLET_TILES;
            case 'mobile':
            default:
                return index + delta * 1;
        }
    };
    const handleClick = (delta) => {
        updateIndex(calculateNewIndex(currentIndex, getDeviceType(), delta));
    };
    return (React.createElement(Container, null,
        React.createElement(IconButton, { "aria-hidden": currentIndex <= 0 && isSuggestedCars, "aria-disabled": currentIndex <= 0, onClick: () => handleClick(-1), deviceType: getDeviceType(), "data-testid": "arrow-icon-back" },
            React.createElement(CoreCiIcon, { icon: "back-large", size: "large" })),
        children,
        React.createElement(IconButton, { "aria-disabled": currentIndex >=
                length - (desktop ? NUMBER_OF_DESKTOP_TILES : tablet ? NUMBER_OF_TABLET_TILES : 1), "aria-hidden": currentIndex >=
                length - (desktop ? NUMBER_OF_DESKTOP_TILES : tablet ? NUMBER_OF_TABLET_TILES : 1) &&
                isSuggestedCars, onClick: () => handleClick(1), deviceType: getDeviceType(), "data-testid": "arrow-icon-forward" },
            React.createElement(CoreCiIcon, { icon: "forward-large", size: "large" }))));
};
const Container = styled.div `
  display: flex;
  justify-content: space-between;
`;
const IconButton = styled.div `
  cursor: pointer;
  padding-top: ${({ deviceType }) => (deviceType === 'desktop' ? '13%' : '18%')};
  align-self: flex-start;
  margin-right: var(${(props) => props.theme.responsive.spacing.s});

  &[aria-disabled='true'] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &[aria-hidden='true'] {
    visibility: hidden;
  }
`;
